import { put, call } from 'redux-saga/effects';
import servicesAPI from 'services/services';
import { servicesActions } from '../../actions';

export function* getLangVersionService(action) {
  const { callBack, ...payload } = action.payload;

  try {
    yield put(servicesActions.getLangVersionService.request());

    const { data } = yield call(servicesAPI.getLangVersionService, payload);
    const { data: dataFields } = data;

    if (dataFields && typeof callBack === 'function') {
      callBack(dataFields);
    }

    yield put(
      servicesActions.getLangVersionService.success({
        filled_locales: dataFields,
      }),
    );
  } catch (err) {
    yield put(servicesActions.getLangVersionService.failure(err));
  }
}
