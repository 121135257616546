import API from './API';

class ServicesAPI extends API {
  getServiceInit = ({ token, langInHeader }) => {
    return this.get('/v1/account/service/init', token, {}, '', langInHeader);
  };

  getServicesList = (token) => {
    return this.get('/v1/account/services', token);
  };

  create = ({ data, token, langInHeader }) => {
    return this.post('/v1/account/service', data, token, langInHeader);
  };

  getServiceAttributesInit = ({ token, langInHeader }) => {
    return this.get('/v1/account/service/search-attributes/init', token, {}, '', langInHeader);
  };

  updateAttributes = ({ data, token, langInHeader }) => {
    return this.put('/v1/account/service/search-attributes', data, token, langInHeader);
  };

  getService = ({ id, token, langInHeader }) => {
    return this.get(`/v1/account/service/${id}`, token, {}, '', langInHeader);
  };

  updateService = ({ data, token, langInHeader }) => {
    return this.put('/v1/account/service', data, token, langInHeader);
  };

  updateServiceWithValidation = ({ data, token, langInHeader }) => {
    return this.put('/v1/account/service/with-validation', data, token, langInHeader);
  };

  getServiceAttributes = ({ id, token, langInHeader }) => {
    return this.get(`/v1/account/services/${id}/search-attributes`, token, {}, '', langInHeader);
  };

  publish = ({ id, data, token, langInHeader }) => {
    return this.post(`/v1/account/service/${id}/publish`, data, token, langInHeader);
  };

  unpublish = ({ id, token }) => {
    return this.post(`/v1/account/service/${id}/unpublish`, {}, token);
  };

  moderate = ({ id, token, langInHeader }) => {
    return this.post(`/v1/account/service/moderate/${id}`, {}, token, langInHeader);
  };

  getLangVersionService = ({ id, token }) => {
    return this.get(`/v1/account/service/filled-locales/${id}`, token, {}, '');
  };
}

export default new ServicesAPI();
