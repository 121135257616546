import { call, put } from 'redux-saga/effects';
import OpportunitiesAPI from 'services/OpportunitiesAPI';
import { opportunitiesActions } from '../../actions';

export function* getOpportunities(action) {
  try {
    const {
      data: { data },
    } = yield call(OpportunitiesAPI.getOpportunitiesInfo, action.payload);
    yield put(opportunitiesActions.getOpportunities.success({ opportunities: data }));
  } catch (err) {
    yield put(opportunitiesActions.getOpportunities.failure(err));
  }
}
