import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';

const FISTS_COUNTRY_LIST = ['ru', 'by', 'ua'];

const selectAllCountries = (state) => state.allCountries.data;

const selectAllSourceCountries = (state) =>
  sortBy(
    state.allCountries.data.data?.countries?.reduce((acc, item, ind) => {
      const indexCurr = FISTS_COUNTRY_LIST.findIndex((val) => val === item.code);
      if (indexCurr >= 0) {
        acc.unshift({ ...item, sortId: indexCurr });
      } else {
        acc.push({ ...item, sortId: FISTS_COUNTRY_LIST.length + ind });
      }
      return acc;
    }, []),
    'sortId',
  ) || [];

const selectError = (state) => state.allCountries.errorMessage;
const selectIsLoading = (state) => state.allCountries.isLoading;

const loadingAndErrors = () =>
  createSelector(selectIsLoading, selectError, (isLoading, errorMessage) => ({
    isLoading,
    errorMessage,
  }));

export { selectAllCountries, selectAllSourceCountries, selectIsLoading, selectError, loadingAndErrors };
