import { takeLatest, call, put, all } from 'redux-saga/effects';
import Subscription from '@services/Subscription';
import actions from './actions';

export function* postCalculateTotalPriceFromServer(action) {
  try {
    const { data } = yield call(Subscription.postCalculateTotalPrice, action.payload);
    // if request successfully finished
    yield put(actions.postCalculateTotalPrice.success({ total_price: data.data.total_price }));
  } catch (err) {
    // if request failed
    yield put(actions.postCalculateTotalPrice.failure(err));
  }
}

export function* postCalculateTotalPriceSaga() {
  yield all([yield takeLatest(actions.postCalculateTotalPrice.TRIGGER, postCalculateTotalPriceFromServer)]);
}
