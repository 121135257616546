import { takeLatest, call, put, all } from 'redux-saga/effects';
import Appeals from 'services/appeals';
import actions from './actions';

export function* getCompanyLeadsFromServer({ payload }) {
  try {
    const data = yield call(Appeals.getCompanyLeads, payload);
    // if request successfully finished
    yield put(actions.getCompanyLeads.success({ data: data.data, meta: data.meta }));
  } catch (err) {
    // if request failed
    yield put(actions.getCompanyLeads.failure(err));
  }
}
export function* getCompanyLeadsSaga() {
  yield all([yield takeLatest(actions.getCompanyLeads.TRIGGER, getCompanyLeadsFromServer)]);
}
