import API from './API';

const API_BASE = '/v1/account/certificate';

class OrderCertificate extends API {
  getCertificateInit = (token) => this.get(`${API_BASE}/init`, token);

  uploadDocuments = ({ data }) => this.post(`${API_BASE}/document`, data);

  storeCertificate = (data) => {
    return this.post(`${API_BASE}`, data);
  };

  getShowCertificate = (id) => {
    return this.get(`${API_BASE}/${id}`);
  };

  updateStoreCertificate = (data) => this.put(`${API_BASE}`, data);
}

export default new OrderCertificate();
