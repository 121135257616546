import { createSelector } from 'reselect';

const selectError = (state) => state.postSubscribe.errorMessage;
const selectIsLoading = (state) => state.postSubscribe.isLoading;

const loadingAndErrors = () =>
  createSelector(selectIsLoading, selectError, (isLoading, errorMessage) => ({
    isLoading,
    errorMessage,
  }));

export { loadingAndErrors };
