import { call, put } from 'redux-saga/effects';
import servicesAPI from '@services/services';
import { servicesActions } from '../../actions';

export function* getServicesList(action) {
  try {
    yield put(servicesActions.getServicesList.request());

    const { data } = yield call(servicesAPI.getServicesList, action.payload);
    const servicesList = data.data;

    yield put(
      servicesActions.getServicesList.success({
        servicesList,
      }),
    );

    if (!servicesList.length) {
      yield put(servicesActions.servicesRedirect.set());
    }
  } catch (err) {
    yield put(servicesActions.getServicesList.failure(err));
  }
}
