import { createSelector } from 'reselect';

const selectCompanyService = (state) => state.companyService.data;
const selectError = (state) => state.companyService.errorMessage;
const selectIsLoading = (state) => state.companyService.isLoading;

const loadingAndErrors = () =>
  createSelector(selectIsLoading, selectError, (isLoading, errorMessage) => ({
    isLoading,
    errorMessage,
  }));

export { selectCompanyService, selectIsLoading, selectError, loadingAndErrors };
