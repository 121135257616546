import { ReduxRoutines } from '@utils/redux-helper';
import actions from './actions';

const initialState = {
  data: [],
  isLoading: false,
  errorMessage: '',
};

const getExportLeads = (state = initialState, { type, payload }) => {
  const redux = new ReduxRoutines(state);
  switch (type) {
    case actions.getExportLeads.TRIGGER:
      return redux.trigger();
    case actions.getExportLeads.SUCCESS:
      return redux.success(payload);
    case actions.getExportLeads.FAILURE:
      return redux.fail(payload);
    default:
      return state;
  }
};

export { getExportLeads };
