import { takeEvery, all, call } from 'redux-saga/effects';
import { companyStatisticActions as actions } from '../actions';
import { companyStatistic } from './workers';

function* companyStatisticWatcher() {
  yield takeEvery(actions.companyStatistic.TRIGGER, companyStatistic);
}

export function* companyStatisticWatchers() {
  yield all([call(companyStatisticWatcher)]);
}
