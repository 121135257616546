import API from './API';

class CreateCompany extends API {
  getInitCompanyData = ({ token, langInHeader }) => this.get('/v1/account/company/init', token, {}, '', langInHeader);

  getCompanyData = ({ token, langInHeader }) => this.get('/v1/account/company', token, {}, '', langInHeader);

  storeCompanyData = ({ data, token, langInHeader }) => this.post('/v1/account/company', data, token, langInHeader);

  updateCompanyData = ({ data, langInHeader }) => this.put('/v1/account/company', data, '', langInHeader);

  uploadCompanyLogo = ({ data }) => this.post('/v1/account/company/image/logo/upload', data);

  uploadOfficeLogo = ({ data }) => this.post('/v1/account/company/image/office/logo/upload', data);

  uploadOfficeEmployeeAvatar = ({ data }) => this.post('/v1/account/company/image/office/employee/avatar/upload', data);

  moderateCompany = ({ uuid, token }) => this.post(`/v1/account/company/moderate/${uuid}`, {}, token);

  publishCompany = ({ data, token, langInHeader }) =>
    this.post(`/v1/account/company/publish`, data, token, langInHeader);

  unPublishCompany = ({ token, langInHeader }) => this.post(`/v1/account/company/unpublish`, {}, token, langInHeader);

  companyDataPreview = ({ token }) => this.get(`/v1/account/company/preview`, token);

  getLangVersionCompany = ({ token }) => this.get(`/v1/account/company/filled-locales`, token);
}

export default new CreateCompany();
