import { takeEvery, all, call } from 'redux-saga/effects';
import { opportunitiesActions } from '../actions';
import { getOpportunities } from './workers';

function* getOpportunitiesWatcher() {
  yield takeEvery(opportunitiesActions.getOpportunities.TRIGGER, getOpportunities);
}

export function* opportunitiesWatchers() {
  yield all([call(getOpportunitiesWatcher)]);
}
