import { takeLatest, call, put, all } from 'redux-saga/effects';
import Advertisement from '@services/Advertisement';
import { Router as router } from 'next/router';
import actions from './actions';

export function* postCreateAdvertisementFromServer(action) {
  try {
    const { data } = yield call(Advertisement.postCreateAdvertisement, action.payload);
    // if request successfully finished
    yield put(actions.postCreateAdvertisement.success({ data: data.data }));
    router.push('/admin/advertising');
  } catch (err) {
    // if request failed
    yield put(actions.postCreateAdvertisement.failure(err));
  }
}

export function* postCreateAdvertisementSaga() {
  yield all([yield takeLatest(actions.postCreateAdvertisement.TRIGGER, postCreateAdvertisementFromServer)]);
}
