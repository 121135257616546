import { takeEvery, takeLatest, all } from 'redux-saga/effects';
import { companyCertificatesActions as actions } from '../actions';
import { getCompanyCertificates, getCertificatePdf, orderCertificateDelivery } from './workers';

export function* companyCertificatesWatchers() {
  yield all([
    yield takeEvery(actions.getCompanyCertificates.TRIGGER, getCompanyCertificates),
    yield takeEvery(actions.getCertificatePdf.TRIGGER, getCertificatePdf),
    yield takeLatest(actions.orderCertificateDelivery.TRIGGER, orderCertificateDelivery),
  ]);
}
