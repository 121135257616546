import { ReduxRoutines } from '@utils/redux-helper';
import actions from './actions';

const initialState = {
  data: {},
  isLoad: true,
  errMessage: '',
};

const companyServiceCategories = (state = initialState, { type, payload }) => {
  const redux = new ReduxRoutines(state);
  switch (type) {
    case actions.fetchCompanyServiceCategories.TRIGGER:
      return redux.trigger();
    case actions.fetchCompanyServiceCategories.SUCCESS:
      return redux.success(payload);
    case actions.fetchCompanyServiceCategories.FAILURE:
      return redux.fail(payload);
    default:
      return state;
  }
};
export { companyServiceCategories };
