import { call, put } from 'redux-saga/effects';
import CompanyCertificatesAPI from 'services/CompanyCertificatesAPI';
import { companyCertificatesActions as actions } from '../../actions';

export function* getCompanyCertificates() {
  try {
    const { data } = yield call(CompanyCertificatesAPI.getCertificatesList);
    yield put(actions.getCompanyCertificates.success({ certificatesList: data.data }));
  } catch (err) {
    yield put(actions.getCompanyCertificates.failure(err));
  }
}
