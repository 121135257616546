import { createSelector } from 'reselect';

const selectCompanyInfo = (state) => state.companyInfo.data;
const selectError = (state) => state.companyInfo.errorMessage;
const selectIsLoading = (state) => state.companyInfo.isLoading;

const loadingAndErrors = () =>
  createSelector(selectIsLoading, selectError, (isLoading, errorMessage) => ({
    isLoading,
    errorMessage,
  }));

export { selectCompanyInfo, selectIsLoading, selectError, loadingAndErrors };
