import { takeEvery, call, put, all } from 'redux-saga/effects';
import VisaMap from '@services/visaMap';
import actions from './actions';

export function* getVisaMapFromServer(action) {
  const {  ...payload } = action.payload;
  try {
    const { data } = yield call(VisaMap.getVisaMap, payload);
    const { visa_map, visa_statuses, companies_count } = data;

    yield put(
      actions.getVisaMap.success({
        visa_map,
        visa_statuses,
        companies_count
      }),
    );
  } catch (err) {
    yield put(actions.getVisaMap.failure(err));
  }
}
export function* getVisaMapSaga() {
  yield all([yield takeEvery(actions.getVisaMap.TRIGGER, getVisaMapFromServer)]);
}
