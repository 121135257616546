import { ReduxRoutines } from '@utils/redux-helper';
import actions from './actions';

const initialState = {
  data: [],
  isLoading: false,
  shouldSubscriptionRedirect: false,
  errorMessage: '',
};

const getAllSubscriptions = (state = initialState, { type, payload }) => {
  const redux = new ReduxRoutines(state);
  switch (type) {
    case actions.getAllSubscriptions.TRIGGER:
      return redux.trigger();
    case actions.getAllSubscriptions.SUCCESS:
      return redux.success(payload);
    case actions.getAllSubscriptions.FAILURE:
      return redux.fail(payload);
    case actions.getAllSubscriptionsCustom.REDIRECT:
      return { ...state, shouldSubscriptionRedirect: true };
    case actions.getAllSubscriptionsCustom.RESET:
      return { ...state, shouldSubscriptionRedirect: false };
    default:
      return state;
  }
};

export { getAllSubscriptions };
