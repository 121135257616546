import { takeLatest, call, put, all } from 'redux-saga/effects';
import CreateCompanyService from '@services/CreateCompanyService';
import actions from './actions';

export function* fetchCompanyData({ payload }) {
  try {
    const { data } = yield call(CreateCompanyService.getCompanyData, payload);
    const {
      logo,
      logo_uuid,
      name,
      motto,
      type,
      company_achievements,
      company_benefits,
      company_offices,
      company_payment_methods,
      description,
      feedback_form,
      image_library,
      status,
      moderator_comment,
      published,
      show_achievements_block,
      show_benefits_block,
      show_payment_block,
      cash_enabled,
      cashless_enabled,
      cryptocurrencies_enabled,
      cash_currencies,
      cashless_currencies,
      cryptocurrencies,
      uuid,
    } = data.data;

    yield put(
      actions.getCompanyData.success({
        logo,
        logo_uuid,
        name,
        motto,
        type,
        company_achievements,
        company_benefits: company_benefits.map((item) => {
          return {
            uuid: item?.uuid,
            description: item?.description,
            icon_id: item?.image_library?.id,
            icon_url: item?.image_library?.image,
          };
        }),
        company_offices,
        company_payment_methods,
        description: description || '',
        feedback_form,
        image_library,
        status,
        moderator_comment,
        published,
        show_achievements_block,
        show_benefits_block,
        show_payment_block,
        cash_enabled,
        cashless_enabled,
        cryptocurrencies_enabled,
        cash_currencies,
        cashless_currencies,
        cryptocurrencies,
        uuid,
      }),
    );
  } catch (err) {
    yield put(actions.getCompanyData.failure(err));
  }
}

export function* storeCompanyData(e) {
  try {
    const { data } = yield call(CreateCompanyService.storeCompanyData, e.payload);
    const {
      logo_uuid,
      name,
      motto,
      type,
      company_achievements,
      company_benefits,
      company_offices,
      company_payment_methods,
      description,
      feedback_form,
      image_library,
      status,
      published,
      show_achievements_block,
      show_benefits_block,
      show_payment_block,
      cash_enabled,
      cashless_enabled,
      cryptocurrencies_enabled,
      cash_currencies,
      cashless_currencies,
      cryptocurrencies,
      uuid,
    } = data.data;

    yield put(
      actions.storeCompanyData.success({
        logo_uuid,
        name,
        motto,
        type,
        company_achievements,
        company_benefits: company_benefits.map((item) => {
          return {
            uuid: item?.uuid,
            description: item?.description,
            icon_id: item?.image_library?.id,
            icon_url: item?.image_library?.image,
          };
        }),
        company_offices,
        company_payment_methods,
        description,
        feedback_form,
        image_library,
        status,
        published,
        show_achievements_block,
        show_benefits_block,
        show_payment_block,
        cash_enabled,
        cashless_enabled,
        cryptocurrencies_enabled,
        cash_currencies,
        cashless_currencies,
        cryptocurrencies,
        uuid,
      }),
    );
  } catch (err) {
    yield put(actions.storeCompanyData.failure(err));
  }
}

export function* updateCompanyData(e) {
  try {
    const { data } = yield call(CreateCompanyService.updateCompanyData, e.payload);
    const {
      logo_uuid,
      name,
      motto,
      type,
      company_achievements,
      company_benefits,
      company_offices,
      company_payment_methods,
      description,
      feedback_form,
      image_library,
      status,
      published,
      show_achievements_block,
      show_benefits_block,
      show_payment_block,
      cash_enabled,
      cashless_enabled,
      cryptocurrencies_enabled,
      cash_currencies,
      cashless_currencies,
      cryptocurrencies,
      uuid,
    } = data.data;
    yield put(
      actions.updateCompanyData.success({
        logo_uuid,
        name,
        motto,
        type,
        company_achievements,
        company_benefits: company_benefits.map((item) => {
          return {
            uuid: item?.uuid,
            description: item?.description,
            icon_id: item?.image_library?.id,
            icon_url: item?.image_library?.image,
          };
        }),
        company_offices,
        company_payment_methods,
        description,
        feedback_form,
        image_library,
        status,
        published,
        show_achievements_block,
        show_benefits_block,
        show_payment_block,
        cash_enabled,
        cashless_enabled,
        cryptocurrencies_enabled,
        cash_currencies,
        cashless_currencies,
        cryptocurrencies,
        uuid,
      }),
    );
  } catch (err) {
    yield put(actions.updateCompanyData.failure(err));
  }
}

export function* publishCompany(action) {
  try {
    const { data } = yield call(CreateCompanyService.publishCompany, action.payload);
    const {
      logo_uuid,
      name,
      type,
      company_achievements,
      company_benefits,
      company_offices,
      company_payment_methods,
      description,
      feedback_form,
      image_library,
      status,
      published,
      show_achievements_block,
      show_benefits_block,
      show_payment_block,
      cash_enabled,
      cashless_enabled,
      cryptocurrencies_enabled,
      cash_currencies,
      cashless_currencies,
      cryptocurrencies,
      uuid,
    } = data.data;

    yield put(
      actions.publishCompany.success({
        logo_uuid,
        name,
        type,
        company_achievements,
        company_benefits: company_benefits.map((item) => {
          return {
            uuid: item?.uuid,
            description: item?.description,
            icon_id: item?.image_library?.id,
            icon_url: item?.image_library?.image,
          };
        }),
        company_offices,
        company_payment_methods,
        description,
        feedback_form,
        image_library,
        status,
        published,
        show_achievements_block,
        show_benefits_block,
        show_payment_block,
        cash_enabled,
        cashless_enabled,
        cryptocurrencies_enabled,
        cash_currencies,
        cashless_currencies,
        cryptocurrencies,
        uuid,
      }),
    );
  } catch (err) {
    yield put(actions.publishCompany.failure(err));
  }
}

export function* unPublishCompany({ payload }) {
  try {
    const { data } = yield call(CreateCompanyService.unPublishCompany, payload);
    const { published, status } = data.data;

    yield put(actions.unPublishCompany.success({ published, status }));
  } catch (err) {
    yield put(actions.unPublishCompany.failure(err));
  }
}

export function* changeLangVersionCompany(action) {
  const { langInHeader } = action.payload;
  try {
    yield put(actions.changeLangVersionCompany.success({ langInHeader }));
  } catch (err) {
    yield put(actions.changeLangVersionCompany.failure(err));
  }
}

export function* getLangVersionCompany({ payload }) {
  const { callBack } = payload;
  try {
    const { data } = yield call(CreateCompanyService.getLangVersionCompany, payload);
    const { data: dataFields } = data;

    if (dataFields && typeof callBack === 'function') {
      callBack(dataFields);
    }

    yield put(actions.getLangVersionCompany.success({ filled_locales: dataFields }));
  } catch (err) {
    yield put(actions.getLangVersionCompany.failure(err));
  }
}

export default function* getCompanyDataSaga() {
  yield all([
    yield takeLatest(actions.getCompanyData.TRIGGER, fetchCompanyData),
    yield takeLatest(actions.storeCompanyData.TRIGGER, storeCompanyData),
    yield takeLatest(actions.updateCompanyData.TRIGGER, updateCompanyData),
    yield takeLatest(actions.publishCompany.TRIGGER, publishCompany),
    yield takeLatest(actions.unPublishCompany.TRIGGER, unPublishCompany),
    yield takeLatest(actions.changeLangVersionCompany.TRIGGER, changeLangVersionCompany),
    yield takeLatest(actions.getLangVersionCompany.TRIGGER, getLangVersionCompany),
  ]);
}
