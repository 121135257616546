import { takeEvery, all, call } from 'redux-saga/effects';
import { leadActions as actions } from '../actions';
import { getLead } from './workers';

function* getLeadWatcher() {
  yield takeEvery(actions.getLead.TRIGGER, getLead);
}

export function* leadWatchers() {
  yield all([call(getLeadWatcher)]);
}
