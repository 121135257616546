import { saveAs } from 'file-saver';

export const downloadFile = (type, data, name) => {
  switch (type) {
    case 'pdf':
      return saveAs(new Blob([data], { type: 'application/pdf' }), `${name}.pdf`);
    case 'xlsx':
      return saveAs(
        new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
        `${name}.xlsx`,
      );
    default:
      return null;
  }
};

export const downloadChatFile = (type, data, name) => {
  switch (type) {
    case 'pdf':
      return saveAs(data, `${name}.pdf`);
    case 'doc':
      return saveAs(data, `${name}.doc`);
    case 'png':
      return saveAs(data, `${name}.png`);
    case 'jpg':
      return saveAs(data, `${name}.jpg`);
    case 'jpeg':
      return saveAs(data, `${name}.jpeg`);
    case 'docx':
      return saveAs(data, `${name}.docx`);
    default:
      return null;
  }
};
