import { takeLatest, call, put, all } from 'redux-saga/effects';
import CompanyDataService from '@services/companyDataService';
import actions from './actions';

export function* fetchCompanyListServicesFromServer(e) {
  try {
    const { uuid, query } = e.payload;
    // perform request to test promise to fetch some data
    const { data } = yield call(CompanyDataService.getCompanyListServices, { uuid, query });
    // if request successfully finished
    yield put(actions.fetchCompanyListServices.success({ data }));
  } catch (error) {
    // if request failed
    yield put(actions.fetchCompanyListServices.failure(error));
  }
}
export function* companyListServicesSaga() {
  // run fetchCompanyListServicesFromServer on every trigger action
  yield all([yield takeLatest(actions.fetchCompanyListServices.TRIGGER, fetchCompanyListServicesFromServer)]);
}
