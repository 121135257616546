import { call, put } from 'redux-saga/effects';
import servicesAPI from '@services/services';
import { servicesActions } from '../../actions';

export function* getServiceAttributesInit(action) {
  try {
    yield put(servicesActions.getServiceAttributesInit.request());

    const { data } = yield call(servicesAPI.getServiceAttributesInit, action.payload);
    yield put(
      servicesActions.getServiceAttributesInit.success({
        serviceAttributesInit: { ...data.data },
      }),
    );
  } catch (err) {
    yield put(servicesActions.getServiceAttributesInit.failure(err));
  }
}
