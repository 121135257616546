import API from './API';

const API_PREFIX = '/v1/account';
const resType = 'arraybuffer';
class Appeals extends API {
  getCompanyLeads = ({ query, langInHeader }) => this.get(`${API_PREFIX}/company/leads`, '', query, '', langInHeader);

  getUserLeads = ({ query, langInHeader, token }) => {
    return this.get(`${API_PREFIX}/user/leads`, token, query, '', langInHeader);
  };

  updateAppealStatus = (id, newStatus) => this.patch(`${API_PREFIX}/company/leads/${id}/status`, { status: newStatus });

  getLead = (id) => this.get(`${API_PREFIX}/company/lead/${id}`);

  deleteLead = ({ id, token }) => this.delete(`${API_PREFIX}/company/lead/${id}`, token);

  getExportLeads = ({ token }) => this.get(`${API_PREFIX}/company/leads/export`, token, {}, resType);
}

export default new Appeals();
