import { call, put } from 'redux-saga/effects';
import servicesAPI from '@services/services';
import { servicesActions } from '../../actions';

export function* unpublishService(action) {
  try {
    yield put(servicesActions.unpublishService.request());
    const { data } = yield call(servicesAPI.unpublish, action.payload);
    const { published, status } = data.data;
    yield put(servicesActions.unpublishService.success({ published, status }));
  } catch (err) {
    console.log(err);
    yield put(servicesActions.unpublishService.failure(err));
  }
}
