import { call, put } from 'redux-saga/effects';
import CompanyCertificatesAPI from 'services/CompanyCertificatesAPI';
import { downloadFile } from '@utils/fileSaver';
import { companyCertificatesActions as actions } from '../../actions';

export function* getCertificatePdf(action) {
  try {
    const { data } = yield call(CompanyCertificatesAPI.getCertificatePdf, action.payload);
    yield put(actions.getCertificatePdf.success({ data }));
    yield downloadFile('pdf', data, `Certificate-${action.payload}`);
  } catch (err) {
    yield put(actions.getCertificatePdf.failure(err));
  }
}
