import API from './API';

class Subscription extends API {
  getSubscriptionsInit = (tokenStr) => this.get('/v1/account/subscriptions/init', tokenStr);

  postCalculateTotalPrice = ({ data, token }) =>
    this.post(`/v1/account/subscription/calculate-total-price`, data, token);

  buySubscription = ({ data, token }) => this.post(`/v1/account/subscription/checkout`, data, token);

  getAllSubscription = (tokenStr) => this.get('/v1/account/company/subscriptions', tokenStr);
}

export default new Subscription();
