import { ReduxRoutines } from 'utils/redux-helper';
import { leadActions as actions } from './actions';

const initialState = {
  data: {},
  errorMessage: '',
  isLoading: false,
};

export const lead = (state = initialState, { type, payload }) => {
  const redux = new ReduxRoutines(state);

  switch (type) {
    case actions.getLead.CLEAR:
      return redux.clear(initialState);
    case actions.getLead.REQUEST:
      return redux.trigger();
    case actions.getLead.SUCCESS:
      return redux.success(payload);
    case actions.getLead.FAILURE:
      return redux.fail(payload);
    default:
      return { ...state };
  }
};
