import { takeLatest, call, put, all } from 'redux-saga/effects';
import Advertisement from '@services/Advertisement';
import actions from './actions';

export function* getAllAdvertisementFromServer(action) {
  try {
    const query = action.payload;
    const { data } = yield call(Advertisement.getAllCompanyAdvertisement, query);
    // if request successfully finished
    const allAdvertisement = data.data;

    if (allAdvertisement.length > 0) {
      yield put(actions.getAllAdvertisement.success({ data: allAdvertisement, meta: data.meta }));
    } else {
      yield put(actions.getAllAdvertisementCustom.redirect());
    }
  } catch (err) {
    // if request failed
    yield put(actions.getAllAdvertisement.failure(err));
  }
}
export function* getAllAdvertisementSaga() {
  yield all([yield takeLatest(actions.getAllAdvertisement.TRIGGER, getAllAdvertisementFromServer)]);
}
