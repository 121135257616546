import { createRoutine, createRoutineCreator, defaultRoutineStages } from 'redux-saga-routines';

export const REVIEWS_LIST_STORE = 'REVIEWS_LIST_STORE';
export const addReviewsListStoreAction = (payload) => {
  return {
    type: REVIEWS_LIST_STORE,
    payload,
  };
};

const getCompanyReviews = createRoutineCreator([...defaultRoutineStages, 'CLEAR'])('GET_COMPANY_REVIEWS');
const loadMoreCompanyReviews = createRoutine('LOAD_MORE_COMPANY_REVIEWS');
const createCompanyReview = createRoutine('CREATE_COMPANY_REVIEW');
const deleteCompanyReview = createRoutine('DELETE_COMPANY_REVIEW');
const updateCompanyReview = createRoutine('UPDATE_COMPANY_REVIEW');
const addReviewLike = createRoutine('ADD_REVIEW_LIKE');

const createReviewComment = createRoutine('CREATE_REVIEW_COMMENT');
const deleteReviewComment = createRoutine('DELETE_COMMENT');
const updateReviewComment = createRoutine('UPDATE_REVIEW_COMMENT');
const addCommentLike = createRoutine('ADD_COMMENT_LIKE');

const getAuthorReviews = createRoutine('GET_AUTHOR_REVIEWS');
const getCompanyAccountReviews = createRoutine('GET_COMPANY_ACCOUNT_REVIEWS');

const getCompanyLeadUserReviews = createRoutineCreator([...defaultRoutineStages, 'CLEAR'])(
  'GET_COMPANY_LEAD_USER_REVIEWS',
);

export default {
  getCompanyReviews,
  loadMoreCompanyReviews,
  createCompanyReview,
  deleteCompanyReview,
  updateCompanyReview,
  addReviewLike,
  createReviewComment,
  deleteReviewComment,
  updateReviewComment,
  addCommentLike,
  getAuthorReviews,
  getCompanyAccountReviews,
  getCompanyLeadUserReviews,
};
