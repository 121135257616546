import { put } from 'redux-saga/effects';
import { servicesActions } from '../../actions';

export function* changeLangVersionService(action) {
  const { langInHeader } = action.payload;
  try {
    yield put(
      servicesActions.changeLangVersionService.success({
        langInHeader,
      }),
    );
  } catch (err) {
    yield put(servicesActions.changeLangVersionService.failure(err));
  }
}
