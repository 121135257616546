import { getCookie } from '@utils/cookie';
import has from 'lodash/has';
import { IW_API_URL, RUS_API_URL, IW_API_URL_INTERN } from 'constants/settings';
import { IS_BROWSER, IS_SERVER, DEFAULT_LANGUAGE, DEFAULT_CURRENCY } from 'constants/global';
import axios from 'axios';
import fingerprint from 'fingerprintjs2';
import localeDetector from '@utils/helper/localeDetector';

const API_URL = IS_BROWSER ? IW_API_URL : IW_API_URL_INTERN;
const CURRENT_LANGUAGE = IS_BROWSER ? localeDetector : DEFAULT_LANGUAGE; //getCookie('NEXT_LOCALE');
const CURRENT_CURRENCY = getCookie('currency') || DEFAULT_CURRENCY;

const getUrl = ({ endpoint, changeUrlPath, url }) =>
  RUS_API_URL && changeUrlPath.includes(endpoint) ? RUS_API_URL : url;

const defaultHeaderConfig = ({ token, currencyInHeader, langInHeader }) => {
  return {
    Authorization: `Bearer ${token || getCookie('token') || ''}`,
    Accept: 'application/json',
    'X-Currency': currencyInHeader || CURRENT_CURRENCY,
    'X-Localization': langInHeader || CURRENT_LANGUAGE,
  };
};

class API {
  constructor(url, fetchService, fingerService) {
    this.url = url || API_URL;
    this.fetchService = fetchService || axios;
    this.fingerService = fingerService || fingerprint;
  }

  get = async (endpoint, token, params, resType, langInHeader, currencyInHeader) => {
    await this.fingerprint();
    const config = {
      headers: {
        ...defaultHeaderConfig({ token, langInHeader, currencyInHeader }),
        'Content-Type': 'application/json',
      },
      params: { ...params },
      responseType: resType || 'text',
    };
    IS_SERVER && console.log('get', `${this.url}${endpoint}`, JSON.stringify(config));
    return this.fetchService.get(`${this.url}${endpoint}`, config);
  };

  // eslint-disable-next-line no-unused-vars
  post = async (endpoint, data, token, langInHeader, socketId, currencyInHeader) => {
    await this.fingerprint();
    const changeUrlPath = ['/v1/auth/avatar', '/v1/lead'];
    const url = getUrl({ endpoint, changeUrlPath, url: this.url });

    const headers = {
      ...defaultHeaderConfig({ token, langInHeader, currencyInHeader }),
    };
    if (socketId) headers['X-Socket-Id'] = socketId;
    if (has(data, 'formData')) headers['Content-Type'] = 'multipart/form-data';
    IS_SERVER && console.log('post', `${this.url}${endpoint}`);
    return this.fetchService.post(`${url}${endpoint}`, data, { headers });
  };

  put = async (endpoint, data, token, langInHeader, currencyInHeader) => {
    await this.fingerprint();
    const changeUrlPath = ['/v1/auth/profile'];
    const url = getUrl({ endpoint, changeUrlPath, url: this.url });
    const headers = {
      ...defaultHeaderConfig({ token, langInHeader, currencyInHeader }),
    };
    IS_SERVER && console.log('put', `${this.url}${endpoint}`);

    return this.fetchService.put(`${url}${endpoint}`, data, { headers });
  };
  patch = async (endpoint, data, token, langInHeader, currencyInHeader) => {
    await this.fingerprint();
    const headers = {
      ...defaultHeaderConfig({ token, langInHeader, currencyInHeader }),
    };
    IS_SERVER && console.log('patch', `${this.url}${endpoint}`);
    return this.fetchService.patch(`${this.url}${endpoint}`, data, { headers });
  };
  delete = async (endpoint, token, langInHeader, currencyInHeader) => {
    await this.fingerprint();
    const headers = {
      ...defaultHeaderConfig({ token, langInHeader, currencyInHeader }),
    };
    !IS_BROWSER && console.log('delete', `${this.url}${endpoint}`);
    return this.fetchService.delete(`${this.url}${endpoint}`, { headers });
  };

  fingerprint = async () => {
    if (IS_SERVER || !this.fingerService) return;
    this.fetchService.defaults.headers.common = {
      fingerprint: null,
    };
    const sessionFinger = sessionStorage.getItem('finger');
    if (!sessionFinger) {
      const components = await this.fingerService.getPromise();
      const values = components.map((component) => component.value);
      const hash = this.fingerService.x64hash128(values.join(''), 34);
      sessionStorage.setItem('finger', hash);
      this.fetchService.defaults.headers.common.fingerprint = hash;
      return hash;
    }
    this.fetchService.defaults.headers.common.fingerprint = sessionFinger;
  };
}

export default API;
