
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import React, { useEffect } from 'react';
import 'assets/styles/app.scss';
import { wrapper } from 'store';
import NextHead from 'next/head';
import GTM from '@components/GTM';
import { useRouter } from 'next/router';
import { pageview } from '@utils/helper/ga';
import Script from 'next/script';
import { geoLocationActions } from '@store/getLocation';
import { connect, useDispatch, useSelector } from 'react-redux';

const RootComponent = ({ Component, pageProps }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const canonicalGenerate = () => {
    const { pathname, query } = router;

    const replacedUrl = pathname
      .split('/')
      .map((segment) => {
        const regKey = segment.match(/\[(.*?)\]/);
        if (regKey) {
          const key = regKey[1];
          return query[key] || segment;
        }
        return segment;
      })
      .join('/');

    return `https://iworld.com/${router.locale}${replacedUrl}`;
  };

  const canonicalLink = router.pathname === '/' ? `https://iworld.com/${router.locale}` : canonicalGenerate();

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (window.ga) {
        window.ga('set', 'page', url);
        window.ga('send', 'pageview', location.pathname);
      }
      pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    dispatch(geoLocationActions.getGeoLocation.trigger());
  }, []);

  return (
    <>
      <NextHead>
        <meta property="og:url" content={canonicalLink} />
        <link rel="canonical" href={canonicalLink} />
        <GTM route={router} />
      </NextHead>
      <Component {...pageProps} />
      <Script src="/static/scripts/modernizr-custom.js" />
    </>
  );
};

// RootComponent.getInitialProps = async ({Component, ctx}) => {
//   const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
//   return { pageProps };
// };

const __Page_Next_Translate__ = wrapper.withRedux(RootComponent);


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  