import { takeLatest, call, put, all } from 'redux-saga/effects';
import Advertisement from '@services/Advertisement';
import actions from './actions';

export function* getAdvertisementInitFromServer(action) {
  try {
    const { data } = yield call(Advertisement.getAdvertisementInit, action.payload);
    // if request successfully finished
    yield put(actions.getAdvertisementInit.success({ data: data.data }));
  } catch (err) {
    // if request failed
    yield put(actions.getAdvertisementInit.failure(err));
  }
}
export function* getAdvertisementInitSaga() {
  yield all([yield takeLatest(actions.getAdvertisementInit.TRIGGER, getAdvertisementInitFromServer)]);
}
