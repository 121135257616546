import { takeLatest, call, put, all } from 'redux-saga/effects';
import OrderCertificate from '@services/OrderCertificate';
import actions from './actions';

export function* getCertificateInitFromServer(action) {
  try {
    const { data } = yield call(OrderCertificate.getCertificateInit, action.payload);
    // if request successfully finished
    yield put(actions.getCertificateInit.success({ data: data.data }));
  } catch (err) {
    // if request failed
    yield put(actions.getCertificateInit.failure(err));
  }
}
export function* getCertificateInitSaga() {
  yield all([yield takeLatest(actions.getCertificateInit.TRIGGER, getCertificateInitFromServer)]);
}
