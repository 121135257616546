import { takeLatest, call, put, all } from 'redux-saga/effects';
import CompanyDataService from '@services/companyDataService';
import actions from './actions';

export function* fetchCompanyServiceFromServer(e) {
  try {
    // perform request to test promise to fetch some data
    const uuid = e.payload;
    const { data } = yield call(CompanyDataService.getCompanyService, uuid);

    // const response = yield call(() => new Promise((resolve, reject) => resolve(data.data)));
    // if request successfully finished
    // yield put(actions.fetchCompanyService.success({data: response}));
    yield put(actions.fetchCompanyService.success({ data: data.data }));
  } catch (error) {
    // if request failed
    yield put(actions.fetchCompanyService.failure(error));
  }
}
export function* companyServiceSaga() {
  // run fetchCompanyServiceFromServer on every trigger action
  yield all([yield takeLatest(actions.fetchCompanyService.TRIGGER, fetchCompanyServiceFromServer)]);
}
