import { takeLatest, call, put, all } from 'redux-saga/effects';
import Subscription from '@services/Subscription';
import actions from './actions';

export function* getSubscriptionInitFromServer(action) {
  try {
    const { data } = yield call(Subscription.getSubscriptionsInit, action.payload);
    // if request successfully finished
    yield put(actions.getSubscriptionInit.success({ data: data.data }));
  } catch (err) {
    // if request failed
    yield put(actions.getSubscriptionInit.failure(err));
  }
}
export function* getSubscriptionInitSaga() {
  yield all([yield takeLatest(actions.getSubscriptionInit.TRIGGER, getSubscriptionInitFromServer)]);
}
