import { ReduxRoutines } from '@utils/redux-helper';
import actions from './actions';

const initialState = {
  country_id: 0,
  legal_form_id: null,
  company_name: '',
  register_number: '',
  address: '',
  activity_type: '',
  contact_person: '',
  contact_phone: '',
  contact_email: '',
  filler_name: '',
  legal_chart: false,
  thanks_letters: false,
  social_activity: false,
  experience_exchange: false,
  documents: [],
  backendResult: {},
};

const storeCertificate = (state = initialState, { type, payload }) => {
  const redux = new ReduxRoutines(state);
  switch (type) {
    case actions.clearStoredCertificate.TRIGGER:
      return { ...state, backendResult: {} };
    case actions.storeCertificate.TRIGGER:
      return redux.trigger();
    case actions.storeCertificate.SUCCESS:
      return redux.success(payload);
    case actions.storeCertificate.FAILURE:
      return redux.fail(payload);
    default:
      return { ...state };
  }
};

export { storeCertificate };
