import API from './API';

class WebSubscription extends API {
  subscribe = (data) => this.post('/v1/subscribe', data);
  unsubscribe = ({ data }) => this.post(`/v1/unsubscribe`, data);

  subscribeArticles = (data) => this.post('/v1/mailing/subscribe', data);
  confirmEmailArticles = ({ data }) => this.post('/v1/mailing/confirm', data);
}

export default new WebSubscription();
