import { takeEvery, all, call } from 'redux-saga/effects';
import { layoutStoreActions } from '../actions';
import { errorModalState } from './workers';

function* errorModalStateWatch() {
  yield takeEvery(layoutStoreActions.errorModalState.TRIGGER, errorModalState);
}

export function* layoutStoreWatchers() {
  yield all([call(errorModalStateWatch)]);
}
