import { put } from 'redux-saga/effects';
import { layoutStoreActions } from '../../actions';

export function* errorModalState(action) {
  const { errorModal, errorText, subscBtnState, errorTitle } = action.payload;
  try {
    yield put(
      layoutStoreActions.errorModalState.success({
        errorModal,
        errorText,
        subscBtnState,
        errorTitle,
      }),
    );
  } catch (err) {
    yield put(layoutStoreActions.errorModalState.failure(err));
  }
}
