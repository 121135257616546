const filterSlugParams = ({
  country_id,
  country_destination_id,
  immigration_purpose_id,
  category_id,
  parent_category_id,
}) => {
  let query = {};
  const paramsKeys = {
    source_country: country_id,
    target_country: country_destination_id,
    immigration_purposes: immigration_purpose_id,
    categories: category_id,
    parent_categories: parent_category_id,
  };

  Object.keys(paramsKeys).forEach((key) => {
    if (paramsKeys[key]) query = { ...query, [key]: [paramsKeys[key]] };
  });

  return query;
};

export { filterSlugParams };
