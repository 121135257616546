import { createRoutine } from 'redux-saga-routines';

const profile = createRoutine('GET_PROFILE_DATA');
const phoneCode = createRoutine('GET_PHONE_CODE');
const profileUpdate = createRoutine('UPDATA_PROFILE_DATA');
const uploadAvatar = createRoutine('UPLOAD_AVATAR');
const clearStoreProfile = createRoutine('CLEAR_STORE_PROFILE');

export default {
  profile,
  profileUpdate,
  phoneCode,
  clearStoreProfile,
  uploadAvatar,
};
