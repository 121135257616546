import { call, put } from 'redux-saga/effects';
import OrderCertificate from 'services/OrderCertificate';
import { showAndUpdateCertificateActions as actions } from '../../actions';

export function* updateStoreCertificate(action) {
  try {
    const { data } = yield call(OrderCertificate.updateStoreCertificate, action.payload);
    yield put(actions.updateStoreCertificate.success({ backendResultUpdateItem: data.data }));
  } catch (err) {
    yield put(actions.updateStoreCertificate.failure(err));
  }
}
