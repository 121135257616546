import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import PassportsAPI from 'services/PassportsAPI';
import actions from './actions';

export function* getPassportsData({ payload }) {
  try {
    const params = yield select(({passports})=> passports.params);
    const {data} = yield call(PassportsAPI.getPassports, {...params, ...payload});
    // yield put( actions.clearPassports.trigger());
    yield put( actions.passports.success(data));
    yield put( actions.setPassportParams.success({...params, ...payload}));
  } catch (err) {
    console.log('err saga', err);
  }
}

export default function* passportsWatchers() {
  yield takeLatest(actions.passports.TRIGGER, getPassportsData)
}
