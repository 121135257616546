import actions from './actions';

const initialState = {
  showForm: {
    open: false,
    country: ''
  }
};

const formLeadPopup = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.setShowForm.TRIGGER:
      return {...state, showForm: payload};
    case actions.setShowForm.CLEAR:
      return {...initialState};
    default:
      return { ...state };
  }
};

export { formLeadPopup };
