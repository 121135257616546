import { createRoutine, createRoutineCreator, defaultRoutineStages } from 'redux-saga-routines';

const getCompanyData = createRoutineCreator([...defaultRoutineStages, 'CLEAR'])('GET_COMPANY_DATA');
const storeCompanyData = createRoutine('STORE_COMPANY_DATA');
const updateCompanyData = createRoutine('UPDATE_COMPANY_DATA');
const uploadCompanyLogo = createRoutine('UPLOAD_COMPANY_LOGO');
const uploadOfficeLogo = createRoutine('UPLOAD_OFFICE_LOGO');
const uploadOfficeEmployeeAvatar = createRoutine('UPLOAD_OFFICE_EMPLOYEE_AVATAR');
const publishCompany = createRoutine('PUBLISH_COMPANY');
const unPublishCompany = createRoutine('UN_PUBLISH_COMPANY');
const changeLangVersionCompany = createRoutine('CHANGE_LANGUAGE_VERSION_COMPANY');
const getLangVersionCompany = createRoutine('GET_LANGUAGE_VERSION_COMPANY');

export default {
  getCompanyData,
  storeCompanyData,
  updateCompanyData,
  uploadCompanyLogo,
  uploadOfficeLogo,
  uploadOfficeEmployeeAvatar,
  publishCompany,
  unPublishCompany,
  changeLangVersionCompany,
  getLangVersionCompany,
};
