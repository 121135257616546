import { call, put } from 'redux-saga/effects';
import ContactsAPI from 'services/ContactsAPI';
import ErrorParser from 'utils/redux-helper/errorParser';
import { postContactsActions as actions } from '../../actions';

export function* postContacts(action) {
  const errorParser = new ErrorParser();
  const { callBack, ...payload } = action.payload;
  try {
    const { data } = yield call(ContactsAPI.postContacts, payload);
    if (data && typeof callBack === 'function') {
      callBack(Promise.resolve(data));
    }
    yield put(actions.postContacts.success({ data }));
  } catch (err) {
    if (err && typeof callBack === 'function') {
      callBack(Promise.reject(errorParser.parseErrorMessaage(err)));
    }
    yield put(actions.postContacts.failure(err));
  }
}
