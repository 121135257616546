import { takeLatest, call, put, all } from 'redux-saga/effects';
import Appeals from '@services/appeals';
import { downloadFile } from '@utils/fileSaver';
import actions from './actions';

export function* getExportLeadsFromServer(action) {
  try {
    const { data } = yield call(Appeals.getExportLeads, action.payload);
    // if request successfully finished
    yield put(actions.getExportLeads.success({ data }));
    yield downloadFile('xlsx', data, 'leads');
  } catch (err) {
    // if request failed
    yield put(actions.getExportLeads.failure(err));
  }
}
export function* getExportLeadsSaga() {
  yield all([yield takeLatest(actions.getExportLeads.TRIGGER, getExportLeadsFromServer)]);
}
