import { ReduxRoutines } from '@utils/redux-helper';
import actions from './actions';

const initialState = {
  errorMessage: '',
  isLoading: true,
  list: {},
  visa_statuses: {},
  selectedCountries: [],
  params: {
    countries: [],
    sort: 'asc',    //sort: asc, continent, differences
  },
};

function Passports(state = initialState, { type, payload }) {
  const redux = new ReduxRoutines(state);
  switch (type) {
    case actions.passports.TRIGGER:
      return redux.trigger();
    case actions.passports.SUCCESS:
      return redux.success(payload);
    case actions.passports.FAILURE:
      return redux.fail(payload);
    case actions.passports.CLEAR:
      return redux.clear(initialState);
    case actions.setSelectedCountry.SUCCESS:
      return redux.success({...state, selectedCountries: payload});
    case actions.setPassportParams.SUCCESS:
      return { ...state, params: {...state.params, ...payload} }
    default:
      return { ...state };
  }
}

export default Passports;
