import { call, put } from 'redux-saga/effects';
import CompanyStatisticAPI from 'services/CompanyStatisticAPI';
import { companyStatisticActions as actions } from '../../actions';

export function* companyStatistic(action) {
  try {
    const { data } = yield call(CompanyStatisticAPI.postCompanyStatistic, action.payload);
    yield put(
      actions.companyStatistic.success({
        item: data.data,
      }),
    );
  } catch (err) {
    yield put(actions.companyStatistic.failure(err));
  }
}
