import { getCookie } from '@utils/cookie';
import { ReduxRoutines } from '@utils/redux-helper';
import actions from './actions';

const initialState = {
  token: getCookie('token') || null,
  isLoading: false,
  errorMessage: '',
  code: null,
  user: getCookie('user')?.data || {}, // refactor this
  logError: '',
  regError: '',
  email_verified: false,
  reset_password_email: '',
};

// check utils/redux-helper/errorParser.js and /utils/redux-helper/reducer-routines.js

function auth(state = initialState, { type, payload }) {
  const redux = new ReduxRoutines(state);
  switch (type) {
    case actions.registration.TRIGGER:
    case actions.registrationWeb.TRIGGER:
    case actions.login.TRIGGER:
    case actions.loginWeb.TRIGGER:
    case actions.socialLogin.TRIGGER:
    case actions.socialLoginWeb.TRIGGER:
    case actions.socialSignUp.TRIGGER:
    case actions.socialSignUpWeb.TRIGGER:
    case actions.logout.TRIGGER:
    case actions.emailActivate.TRIGGER:
    case actions.sendActivationEmail.TRIGGER:
      return redux.trigger();
    case actions.registration.SUCCESS:
    case actions.registrationWeb.SUCCESS:
    case actions.login.SUCCESS:
    case actions.loginWeb.SUCCESS:
    case actions.socialLogin.SUCCESS:
    case actions.socialLoginWeb.SUCCESS:
    case actions.socialSignUp.SUCCESS:
    case actions.socialSignUpWeb.SUCCESS:
    case actions.logout.SUCCESS:
    case actions.emailActivate.SUCCESS:
    case actions.sendActivationEmail.SUCCESS:
      return redux.success(payload);
    case actions.socialLogin.FAILURE:
    case actions.socialLoginWeb.FAILURE:
    case actions.socialSignUp.FAILURE:
    case actions.socialSignUpWeb.FAILURE:
    case actions.registration.FAILURE:
    case actions.registrationWeb.FAILURE:
    case actions.login.FAILURE:
    case actions.loginWeb.FAILURE:
    case actions.emailActivate.FAILURE:
    case actions.sendActivationEmail.FAILURE:
      return redux.fail(payload);
    case actions.init.TRIGGER:
      return redux.init();
    default:
      return state;
  }
}

export { auth };
