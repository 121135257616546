import { takeEvery, all, call } from 'redux-saga/effects';
import { recommendedCompaniesActions as actions } from '../actions';
import { getRecommendedCompaniesList } from './workers';

function* getListWatcher() {
  yield takeEvery(actions.getRecommendedCompaniesList.TRIGGER, getRecommendedCompaniesList);
}

export function* recommendedCompaniesWatchers() {
  yield all([call(getListWatcher)]);
}
