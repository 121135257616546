import { takeLatest, call, put, all } from 'redux-saga/effects';
import CreateCompanyService from '@services/CreateCompanyService';
import actions from './actions';
// move this to getCompanyData
export function* fetchInitCompanyData(action) {
  try {
    const { data } = yield call(CreateCompanyService.getInitCompanyData, action.payload);
    yield put(
      actions.getInitData.success({
        feedback_form_types: data.data.feedback_form_types,
        messengers: data.data.messengers,
        office_types: data.data.office_types,
        payment_methods: data.data.payment_methods,
        cash_currencies: data.data.cash_currencies,
        cashless_currencies: data.data.cashless_currencies,
        cryptocurrencies: data.data.cryptocurrencies,
        company_types: data.data.company_types,
      }),
    );
  } catch (err) {
    yield put(actions.getInitData.failure(err));
  }
}

export default function* getInitDataSaga() {
  yield all([yield takeLatest(actions.getInitData.TRIGGER, fetchInitCompanyData)]);
}
