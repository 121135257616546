import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { fork, all } from 'redux-saga/effects';
import { createWrapper } from 'next-redux-wrapper';
import { profile, profileSaga } from './profile';
import { auth, authSaga } from './auth';
import { companyInfo, companyInfoSaga } from './company/companyInfo';
import { companyService, companyServiceSaga } from './company/companyService';
import { companyListServices, companyListServicesSaga } from './company/companyListServices';
import { companyServiceCategories, companyServiceCategoriesSaga } from './company/companyServiceCategories';
import { getInitData, getInitDataSaga } from './createCompany/getInitData';
import { getCompanyData, getCompanyDataSaga } from './createCompany/getCompanyData';
import { searchCompanies, getAllCompaniesSaga } from './searchAllCompanies';
import { allCountries, getAllCountriesSaga } from './allCountries';
import { searchInit, getSearchInitSaga } from './searchInit';
import { allCompanyReviews, getCompanyReviewsSaga } from './reviews';
import { allServices, allServicesSaga } from './allServices';
import { services, watchServices } from './services';
import { getSubscriptionInit, getSubscriptionInitSaga } from './subscription/getSubscriptionInit';
import { postCalculateTotalPrice, postCalculateTotalPriceSaga } from './subscription/postCalculateTotal';
import { getAllSubscriptions, getAllSubscriptionsSaga } from './subscription/getAllSubscription';
import { buySubscriptions, buySubscriptionSaga } from './subscription/buySubscription';
import { getAdvertisementInit, getAdvertisementInitSaga } from './advertisement/getAdvertisementInit';
import {
  postCalculatePriceAdvertisement,
  postCalculatePriceAdvertisementSaga,
} from './advertisement/postCalculatePriceAdvertisement';
import { postCreateAdvertisement, postCreateAdvertisementSaga } from './advertisement/postCreateAdvertisement';
import { getAllAdvertisement, getAllAdvertisementSaga } from './advertisement/getAllAdvertisement';
import { storeLead, postStoreLeadSaga } from './storeLead';
import { getCompanyLeads, getCompanyLeadsSaga } from './appeals/getCompanyLeads';
import { lead, leadWatchers } from './appeals/getLead';
import { getUserLeads, getUserLeadsSaga } from './appeals/getUserLeads';
import { deleteLead, deleteLeadSaga } from './appeals/deleteLead';
import { getExportLeads, getExportLeadsSaga } from './appeals/getExportLeads';
import { postImprovementConstructor, postImprovementConstructorSaga } from './improvementConstructor';
import { serviceDataPreview, serviceDataPreviewSaga } from './serviceDataPreview';
import { postSubscribe, postSubscribeSaga } from './webSubscription';
import { getVisaMap, getVisaMapSaga } from './visaFreeMap';
import { recommendedCompanies, recommendedCompaniesWatchers } from './recommendedCompanies';
import { layoutStore, layoutStoreWatchers } from './layoutStore';
import { getCertificateInit, getCertificateInitSaga } from './orderCertificate/getCertificateInit';
import { storeCertificate, storeCertificateSaga } from './orderCertificate/storeCertificate';
import {
  showAndUpdateCertificate,
  showAndUpdateCertificateWatchers,
} from './orderCertificate/showAndUpdateCertificate';
import { companyCertificates, companyCertificatesWatchers } from './companyCertificates';
import { geoLocation, getGeoLocationWatchers } from './getLocation';
import { postContacts, postContactsWatchers } from './contacts';
import { feedBackForSearchCompanies, createFeedBackForSearchCompaniesWatchers } from './feedBackForSearchCompanies';
import { visaMapCountries, visaMapCountriesWatchers } from './visaMapCountries';
import { opportunities, opportunitiesWatchers } from './opportunities';
import { companyStatistic, companyStatisticWatchers } from './companyStatistic';
import { passports, passportsWatchers } from './passports';
import { seoSlugListReducer } from './seoSlugList';
import {formLeadPopup} from "@store/formLeadPopup";

const arraySaga = [
  fork(authSaga),
  fork(opportunitiesWatchers),
  fork(allServicesSaga),
  fork(buySubscriptionSaga),
  fork(companyCertificatesWatchers),
  fork(companyInfoSaga),
  fork(companyListServicesSaga),
  fork(companyServiceCategoriesSaga),
  fork(companyServiceSaga),
  fork(createFeedBackForSearchCompaniesWatchers),
  fork(deleteLeadSaga),
  fork(getAdvertisementInitSaga),
  fork(getAllAdvertisementSaga),
  fork(getAllCompaniesSaga),
  fork(getAllCountriesSaga),
  fork(getAllSubscriptionsSaga),
  fork(getCertificateInitSaga),
  fork(getCompanyDataSaga),
  fork(getCompanyLeadsSaga),
  fork(getCompanyReviewsSaga),
  fork(getExportLeadsSaga),
  fork(getGeoLocationWatchers),
  fork(getInitDataSaga),
  fork(getSearchInitSaga),
  fork(getSubscriptionInitSaga),
  fork(getUserLeadsSaga),
  fork(getVisaMapSaga),
  fork(layoutStoreWatchers),
  fork(leadWatchers),
  fork(postCalculatePriceAdvertisementSaga),
  fork(postCalculateTotalPriceSaga),
  fork(postCreateAdvertisementSaga),
  fork(postImprovementConstructorSaga),
  fork(postStoreLeadSaga),
  fork(postSubscribeSaga),
  fork(profileSaga),
  fork(recommendedCompaniesWatchers),
  fork(serviceDataPreviewSaga),
  fork(storeCertificateSaga),
  fork(showAndUpdateCertificateWatchers),
  fork(postContactsWatchers),
  fork(watchServices),
  fork(visaMapCountriesWatchers),
  fork(companyStatisticWatchers),
  fork(passportsWatchers),
];

export const rootReducer = combineReducers({
  opportunities,
  allCompanyReviews,
  allCountries,
  allServices,
  auth,
  buySubscriptions,
  companyCertificates,
  companyInfo,
  companyListServices,
  companyService,
  companyServiceCategories,
  deleteLead,
  feedBackForSearchCompanies,
  geoLocation,
  getAdvertisementInit,
  getAllAdvertisement,
  getAllSubscriptions,
  getCertificateInit,
  getCompanyData,
  getCompanyLeads,
  getExportLeads,
  getInitData,
  getSubscriptionInit,
  getUserLeads,
  getVisaMap,
  layoutStore,
  lead,
  postCalculatePriceAdvertisement,
  postCalculateTotalPrice,
  postCreateAdvertisement,
  postImprovementConstructor,
  postSubscribe,
  profile,
  recommendedCompanies,
  searchCompanies,
  searchInit,
  serviceDataPreview,
  services,
  storeCertificate,
  showAndUpdateCertificate,
  storeLead,
  postContacts,
  visaMapCountries,
  companyStatistic,
  seoSlugListReducer,
  passports,
  formLeadPopup
});

const bindMiddleware = (middleware) => {
  // if (process.env.NODE_ENV !== 'production') {
  //   return composeWithDevTools(applyMiddleware(...middleware));
  // }
  return applyMiddleware(...middleware);
};

const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  function* rootSaga() {
    yield all(arraySaga);
  }

  const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

const wrapper = createWrapper(makeStore);
export { makeStore, wrapper };
