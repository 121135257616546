import { ReduxRoutines } from '@utils/redux-helper';
import actions from './actions';

const initialState = {
  data: {},
  isLoading: true,
  errorMessage: '',
};

const companyInfo = (state = initialState, { type, payload }) => {
  const redux = new ReduxRoutines(state);
  switch (type) {
    case actions.fetchCompanyInfo.CLEAR:
      return redux.clear(initialState);
    case actions.fetchCompanyInfo.TRIGGER:
      return redux.trigger();
    case actions.fetchCompanyInfo.SUCCESS:
      return redux.success(payload);
    case actions.fetchCompanyInfo.FAILURE:
      return redux.fail(payload);
    default:
      return state;
  }
};

export { companyInfo };
