import { takeLatest, call, put, all } from 'redux-saga/effects';
import ImprovementConstructor from '@services/improvementConstructor';
import actions from './actions';

export function* postImprovementConstructorFromServer(action) {
  try {
    const { data } = yield call(ImprovementConstructor.postImprovementConstructor, action.payload);
    yield put(actions.postImprovementConstructor.success({ data }));
  } catch (err) {
    yield put(actions.postImprovementConstructor.failure(err));
  }
}

export function* postImprovementConstructorSaga() {
  yield all([yield takeLatest(actions.postImprovementConstructor.TRIGGER, postImprovementConstructorFromServer)]);
}
