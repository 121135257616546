import { ReduxRoutines } from '@utils/redux-helper';
import actions from './actions';

const initialState = {
  allServices: [],
  isLoading: false,
  errorMessage: '',
};

function allServices(state = initialState, { type, payload }) {
  const redux = new ReduxRoutines(state);

  switch (type) {
    case actions.allServices.TRIGGER:
      return redux.trigger();
    case actions.allServices.SUCCESS:
      return redux.success(payload);
    case actions.allServices.FAILURE:
      return redux.fail(payload);
    default:
      return { ...state };
  }
}

export default allServices;
