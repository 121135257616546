import { call, put } from 'redux-saga/effects';
import GeoLocationAPI from 'services/LocationAPI';
import { getCookie, setCookie } from 'utils/cookie';
import { geoLocationActions as actions } from '../../actions';

export function* getGeoLocation() {
  let start = true;
  try {
    const country = getCookie('userCountry');
    if (!country && start) {
      start = false;
      yield put(actions.getGeoLocation.request());
      const { data } = yield call(GeoLocationAPI.getLocation);
      const countryCode = data.data?.iso_code;
      yield put(actions.getGeoLocation.success({ item: countryCode.toLowerCase() }));
      setCookie('country', countryCode.toUpperCase(), { max: 0 });
      setCookie('userCountry', countryCode.toUpperCase(), { max: 0 });
    } else {
      start = true;
      yield put(actions.getGeoLocation.success({ item: country.toLowerCase() }));
    }
  } catch (err) {
    yield put(actions.getGeoLocation.failure(err));
  }
}
