import { takeLatest, call, put, all } from 'redux-saga/effects';
import allServices from '@services/allServices';
import actions from './actions';

export function* getAllServices() {
  try {
    const { data } = yield call(allServices.getAllServices, {});

    yield put(actions.allServices.success({ allServices: data.data }));
  } catch (err) {
    yield put(actions.allServices.failure(err));
  }
}

export default function* allServicesSaga() {
  yield all([yield takeLatest(actions.allServices.TRIGGER, getAllServices)]);
}
