import { ReduxRoutines } from '@utils/redux-helper';
import { postContactsActions as actions } from './actions';

const initialState = {
  item: {},
  errorMessage: '',
  isLoading: true,
  total: '',
};

export const postContacts = (state = initialState, { type, payload }) => {
  const redux = new ReduxRoutines(state);

  switch (type) {
    case actions.postContacts.CLEAR:
      return redux.clear(initialState);
    case actions.postContacts.REQUEST:
      return redux.trigger();
    case actions.postContacts.SUCCESS:
      return redux.success(payload);
    case actions.postContacts.FAILURE:
      return redux.fail(payload);
    default:
      return { ...state };
  }
};
