import API from './API';

class CompanyReviews extends API {
  getCompanyReviews = ({ uuid, query, token, lang }) => this.get(`/v1/company/${uuid}/reviews`, token, query, '', lang);

  getCompanyAccountReviews = ({ token, query }) => this.get(`/v1/account/company/reviews`, token, query);

  loadMoreCompanyReviews = ({ uuid, page }) => this.get(`/v1/company/${uuid}/reviews?page=${page}`, '');

  createCompanyReview = ({ uuid, data, token }) => this.post(`/v1/company/${uuid}/review`, data, token);

  deleteCompanyReview = ({ id, token }) => this.delete(`/v1/review/${id}/`, token);

  updateCompanyReview = ({ id, data, token }) => this.put(`/v1/review/${id}`, data, token);

  addReviewLike = ({ id, data, token }) => this.post(`/v1/review/${id}/like`, data, token);

  createReviewComment = ({ data, token }) => this.post(`/v1/review/comment`, data, token);

  deleteReviewComment = ({ id, token }) => this.delete(`/v1/review/comment/${id}`, token);

  updateReviewComment = ({ id, data, token }) => this.put(`/v1/review/comment/${id}`, data, token);

  addCommentLike = ({ id, data, token }) => this.post(`/v1/review/comment/${id}/like`, data, token);

  getAuthorReviews = ({ uuid, token, query }) => this.get(`/v1/author/${uuid}/reviews`, token, query);

  getCompanyLeadUserReviews = ({ uuid }) => this.get(`/v1/account/users/${uuid}/reviews`);
}

export default new CompanyReviews();
