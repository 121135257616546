import { REVIEWS_LIST_STORE } from './actions';
import actions from './actions';

const initialState = {
  data: [],
  isLoading: false,
  errorMessage: null,
  isShowMore: false,
  total: null,
  current_page: null,
  last_page: null,
  per_page: null,
  avg_rate: null,
};

const allCompanyReviews = (state = initialState, { type, payload }) => {
  // const redux = new ReduxRoutines(state);
  switch (type) {
    case REVIEWS_LIST_STORE:
      return { ...state, data: payload };

    case actions.getCompanyReviews.CLEAR:
      return {
        ...state,
        data: [],
        isLoading: false,
      };

    case actions.getCompanyReviews.TRIGGER:
      return { ...state, isLoading: true };
    case actions.getCompanyReviews.SUCCESS:
      return {
        ...state,
        data: payload.data.reviews,
        total: payload.total,
        current_page: payload.current_page,
        last_page: payload.last_page,
        per_page: payload.per_page,
        isLoading: false,
        avg_rate: payload.data.avg_rate,
      };
    case actions.getCompanyReviews.FAILURE:
      return {
        ...state,
        errorMessage: payload,
      };

    case actions.loadMoreCompanyReviews.TRIGGER:
      return { ...state, isShowMore: true };

    case actions.loadMoreCompanyReviews.SUCCESS: {
      const newList = payload.data;
      const { data } = state;
      return {
        ...state,
        data: [...data, ...newList],
        current_page: payload.current_page,
        last_page: payload.last_page,
        isShowMore: false,
      };
    }
    case actions.loadMoreCompanyReviews.FAILURE:
      return {
        ...state,
        errorMessage: payload,
      };
    case actions.createCompanyReview.TRIGGER:
      return {
        ...state,
        // isLoading: true,
      };

    case actions.createCompanyReview.SUCCESS: {
      const newList = payload.data;
      const { data } = state;

      return {
        ...state,
        data: [newList, ...data],
        total: state.total + 1,
        // isLoading: false,
      };
    }
    case actions.createCompanyReview.FAILURE:
      return {
        ...state,
        errorMessage: payload,
      };
    case actions.deleteCompanyReview.TRIGGER:
      return {
        ...state,
        // isLoading: true
      };

    case actions.deleteCompanyReview.SUCCESS: {
      const { data } = state;
      return {
        ...state,
        data: data.filter((item) => item.id !== payload),
        total: state.total - 1,
        // isLoading: false,
      };
    }
    case actions.deleteCompanyReview.FAILURE:
      return {
        ...state,
        errorMessage: payload,
      };
    case actions.updateCompanyReview.TRIGGER:
      return {
        ...state,
        // isLoading: true
      };

    case actions.updateCompanyReview.SUCCESS: {
      const { data } = state;
      const idx = data.findIndex((el) => el.id === payload.data.id);

      return {
        ...state,
        data: [...data.slice(0, idx), payload.data, ...data.slice(idx + 1)],
        // isLoading: false,
      };
    }
    case actions.updateCompanyReview.FAILURE:
      return {
        ...state,
        errorMessage: payload,
      };
    case actions.createReviewComment.TRIGGER:
      return {
        ...state,
        // isLoading: true
      };

    case actions.createReviewComment.SUCCESS: {
      const { review_id } = payload;
      const { data } = state;
      const newArr = [...data];
      const curentReview = newArr.find((item) => item.id === review_id);
      curentReview.comments.push(payload.data);
      const newCount = curentReview.comments_count + 1;
      curentReview.comments_count = newCount;
      const idx = newArr.findIndex((el) => el.id === review_id);
      return {
        ...state,
        data: [...data.slice(0, idx), curentReview, ...data.slice(idx + 1)],
        // isLoading: false,
      };
    }
    case actions.createReviewComment.FAILURE:
      return {
        ...state,
        errorMessage: payload,
      };
    case actions.deleteReviewComment.TRIGGER:
      return {
        ...state,
        // isLoading: true
      };

    case actions.deleteReviewComment.SUCCESS: {
      const { data } = state;
      const { review_id, id } = payload;
      const newArr = [...data];
      const curentReview = newArr.find((item) => item.id === review_id);
      const { comments } = curentReview;
      const filter = comments.filter((item) => item.id !== id);
      curentReview.comments = filter;
      const newCount = curentReview.comments_count - 1;
      curentReview.comments_count = newCount;
      const idx = newArr.findIndex((el) => el.id === review_id);

      return {
        ...state,
        data: [...data.slice(0, idx), curentReview, ...data.slice(idx + 1)],
        // total: state.total - 1,
        // isLoading: false,
      };
    }
    case actions.deleteReviewComment.FAILURE:
      return {
        ...state,
        errorMessage: payload,
      };
    case actions.updateReviewComment.TRIGGER:
      return {
        ...state,
        // isLoading: true
      };

    case actions.updateReviewComment.SUCCESS: {
      const { data } = state;
      // const { review_id, id } = payload;
      const newArr = [...data];
      const curentReview = newArr.find((item) => item.id === payload.review_id);
      const { comments } = curentReview;
      const idxComment = comments.findIndex((item) => item.id === payload.id);
      const newComments = [...comments.slice(0, idxComment), payload.data, ...comments.slice(idxComment + 1)];
      curentReview.comments = newComments;
      const idx = newArr.findIndex((item) => item.id === payload.review_id);

      return {
        ...state,
        data: [...data.slice(0, idx), curentReview, ...data.slice(idx + 1)],
        // isLoading: false,
      };
    }
    case actions.updateReviewComment.FAILURE:
      return {
        ...state,
        errorMessage: payload,
      };
    case actions.addReviewLike.TRIGGER:
      return {
        ...state,
        // isLoading: true
      };
    case actions.addReviewLike.SUCCESS: {
      const { data } = state;
      const newArr = [...data];
      const curentReview = newArr.find((item) => item.id === payload.id);
      curentReview.reacted = !curentReview.reacted;
      curentReview.likes_count =
        curentReview.reacted === false ? curentReview.likes_count - 1 : curentReview.likes_count + 1;
      const idx = newArr.findIndex((item) => item.id === payload.id);
      return {
        ...state,
        data: [...data.slice(0, idx), curentReview, ...data.slice(idx + 1)],
        // isLoading: false,
      };
    }
    case actions.addReviewLike.FAILURE:
      return {
        ...state,
        errorMessage: payload,
      };
    case actions.addCommentLike.TRIGGER:
      return {
        ...state,
        // isLoading: true
      };
    case actions.addCommentLike.SUCCESS: {
      const { data } = state;
      const { like } = payload.data;
      const newArr = [...data];
      const curentReview = newArr.find((item) => item.id === payload.review_id);
      const { comments } = curentReview;
      const currentComment = comments.find((item) => item.id === payload.id);
      if (currentComment.reaction_type === 'like') {
        if (like === false) {
          currentComment.dislikes_count += 1;
          currentComment.likes_count -= 1;
          currentComment.reaction_type = 'dislike';
        }
        if (like === true) {
          currentComment.likes_count -= 1;
          currentComment.reacted = !currentComment.reacted;
          currentComment.reaction_type = undefined;
        }
      } else if (currentComment.reaction_type === 'dislike') {
        if (like === false) {
          currentComment.dislikes_count -= 1;
          currentComment.reacted = !currentComment.reacted;
          currentComment.reaction_type = undefined;
        }
        if (like === true) {
          currentComment.likes_count += 1;
          currentComment.dislikes_count -= 1;
          currentComment.reaction_type = 'like';
        }
      } else {
        if (like === false) {
          currentComment.dislikes_count += 1;
          currentComment.reacted = !currentComment.reacted;
          currentComment.reaction_type = 'dislike';
        }
        if (like === true) {
          currentComment.likes_count += 1;
          currentComment.reacted = !currentComment.reacted;
          currentComment.reaction_type = 'like';
        }
      }
      const idxComment = comments.findIndex((item) => item.id === payload.id);
      const newComments = [...comments.slice(0, idxComment), currentComment, ...comments.slice(idxComment + 1)];
      curentReview.comments = newComments;
      const idx = newArr.findIndex((item) => item.id === payload.review_id);

      return {
        ...state,
        data: [...data.slice(0, idx), curentReview, ...data.slice(idx + 1)],
        // isLoading: false,
      };
    }
    case actions.addCommentLike.FAILURE:
      return {
        ...state,
        errorMessage: payload,
      };
    case actions.getAuthorReviews.TRIGGER:
      return { ...state, isLoading: true };
    case actions.getAuthorReviews.SUCCESS: {
      return {
        ...state,
        data: payload.data.reviews,
        total: payload.total,
        current_page: payload.current_page,
        last_page: payload.last_page,
        per_page: payload.per_page,
        isLoading: false,
      };
    }
    case actions.getAuthorReviews.FAILURE:
      return {
        ...state,
        errorMessage: payload,
      };
    case actions.getCompanyAccountReviews.TRIGGER:
      return { ...state, isLoading: true };
    case actions.getCompanyAccountReviews.SUCCESS:
      return {
        ...state,
        data: payload.data.reviews,
        total: payload.total,
        current_page: payload.current_page,
        last_page: payload.last_page,
        per_page: payload.per_page,
        isLoading: false,
        avg_rate: payload.data.avg_rate,
      };
    case actions.getCompanyAccountReviews.FAILURE:
      return {
        ...state,
        errorMessage: payload,
      };

    case actions.getCompanyLeadUserReviews.CLEAR:
      return {
        ...state,
        data: [],
        isLoading: false,
      };
    case actions.getCompanyLeadUserReviews.TRIGGER:
      return { ...state, isLoading: true };
    case actions.getCompanyLeadUserReviews.SUCCESS:
      return {
        ...state,
        data: payload.data,
        isLoading: false,
      };
    case actions.getCompanyLeadUserReviews.FAILURE:
      return {
        ...state,
        errorMessage: payload,
      };

    default:
      return state;
  }
};

export { allCompanyReviews };
