import { call, put } from 'redux-saga/effects';
import Appeals from 'services/appeals';
import { leadActions as actions } from '../../actions';

export function* getLead(action) {
  try {
    const { id } = action.payload;
    yield put(actions.getLead.request());
    const { data } = yield call(Appeals.getLead, id);
    yield put(actions.getLead.success({ data: data.data }));
  } catch (err) {
    yield put(actions.getLead.failure(err));
  }
}
