import { takeLatest, call, put, all } from 'redux-saga/effects';
import Advertisement from '@services/Advertisement';
import actions from './actions';

export function* postCalculatePriceAdvertisementFromServer(action) {
  try {
    const { data } = yield call(Advertisement.postCalculatePriceAdvertisement, action.payload);
    // if request successfully finished
    yield put(actions.postCalculatePriceAdvertisement.success({ total_price: data.data.total_price }));
  } catch (err) {
    // if request failed
    yield put(actions.postCalculatePriceAdvertisement.failure(err));
  }
}

export function* postCalculatePriceAdvertisementSaga() {
  yield all([
    yield takeLatest(actions.postCalculatePriceAdvertisement.TRIGGER, postCalculatePriceAdvertisementFromServer),
  ]);
}
