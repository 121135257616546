import { takeLatest, call, put, all } from 'redux-saga/effects';
import Appeals from '@services/appeals';
import actions from './actions';

export function* deleteLeadFromServer(action) {
  try {
    yield call(Appeals.deleteLead, action.payload);
    // if request successfully finished
    yield put(actions.deleteLead.success(action.payload));
  } catch (err) {
    // if request failed
    yield put(actions.deleteLead.failure(err));
  }
}
export function* deleteLeadSaga() {
  yield all([yield takeLatest(actions.deleteLead.TRIGGER, deleteLeadFromServer)]);
}
