import { createSelector } from 'reselect';

const selectCompanyListServices = (state) => state.companyListServices.data;
const selectError = (state) => state.companyListServices.errorMsg;
const selectIsLoading = (state) => state.companyListServices.loading;

const loadingAndErrors = () =>
  createSelector(selectIsLoading, selectError, (loading, errorMsg) => ({
    loading,
    errorMsg,
  }));

export { selectCompanyListServices, selectIsLoading, selectError, loadingAndErrors };
