import { call, put } from 'redux-saga/effects';
import RecommendedCompaniesAPI from 'services/RecommendedCompaniesAPI';
import { recommendedCompaniesActions as actions } from '../../actions';

export function* getRecommendedCompaniesList(action) {
  try {
    yield put(actions.getRecommendedCompaniesList.request());
    const { data } = yield call(RecommendedCompaniesAPI.getRecommendedCompaniesListAPI, action.payload);

    yield put(
      actions.getRecommendedCompaniesList.success({
        data: data.data,
      }),
    );
  } catch (err) {
    yield put(actions.getRecommendedCompaniesList.failure(err));
  }
}
