import { call, put } from 'redux-saga/effects';
import OrderCertificate from 'services/OrderCertificate';
import { showAndUpdateCertificateActions as actions } from '../../actions';

export function* getShowCertificate(action) {
  try {
    const { data } = yield call(OrderCertificate.getShowCertificate, action.payload);
    yield put(actions.getShowCertificate.success({ item: data.data }));
  } catch (err) {
    yield put(actions.getShowCertificate.failure(err));
  }
}
