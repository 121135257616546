import { takeLatest, call, put, all } from 'redux-saga/effects';
import ProfileService from 'services/ProfileService';
import actions from './actions';

import logoutAction from '../auth/actions';

// refactor this
export function* getProfileData({ payload }) {
  try {
    const { data } = yield call(ProfileService.profileData, payload);

    const {
      avatar,
      email,
      first_name,
      last_name,
      position,
      new_leads,
      new_messages,
      phone,
      email_notifications,
      role,
      email_verified,
      uuid,
      registered_at,
      company,
    } = data.data;
    yield put(
      actions.profile.success({
        avatar,
        email,
        first_name,
        last_name,
        position,
        new_leads,
        new_messages,
        phone,
        email_notifications,
        role,
        email_verified,
        uuid,
        registered_at,
        company,
      }),
    );
  } catch (err) {
    yield put(logoutAction.logout.trigger());
  }
}

export function* updateProfileData(e) {
  try {
    const { data } = yield call(ProfileService.profileUpdate, e.payload);
    yield put(
      actions.profileUpdate.success({
        first_name: data.data.first_name,
        last_name: data.data.last_name,
        phone: data.data.phone,
        email: data.data.email,
        position: data.data.position,
        new_messages: data.data.new_messages,
        new_leads: data.data.new_leads,
        email_notifications: data.data.email_notifications,
        email_verified: data.data.email_verified,
        role: data.data.role,
      }),
    );
  } catch (err) {
    yield put(actions.profileUpdate.failure(err));
  }
}

export function* uploadAvatar(action) {
  try {
    const { data } = yield call(ProfileService.uploadAvatar, action.payload);

    yield put(actions.uploadAvatar.success({ avatar: data.data?.filePath }));
  } catch (err) {
    yield put(actions.uploadAvatar.failure(err));
  }
}

export default function* profileSaga() {
  yield all([
    yield takeLatest(actions.profile.TRIGGER, getProfileData),
    yield takeLatest(actions.profileUpdate.TRIGGER, updateProfileData),
    yield takeLatest(actions.uploadAvatar.TRIGGER, uploadAvatar),
  ]);
}
