import { createRoutineCreator, defaultRoutineStages, createRoutine } from 'redux-saga-routines';

const passports = createRoutineCreator([...defaultRoutineStages, 'CLEAR'])('GET_PASSPORTS');
const setPassportParams = createRoutine('SET_PASSPORTS_PARAMS');
const setSelectedCountry = createRoutine('SET_SELECTED_COUNTRY');

export default {
  passports,
  setPassportParams,
  setSelectedCountry
};
