import { takeLatest, call, put, all } from 'redux-saga/effects';
import CompanyDataService from 'services/companyDataService';
import actions from './actions';

export function* fetchCompanyInfoFromServer({ payload }) {
  try {
    const { uuid, callBack } = payload;
    const { data } = yield call(CompanyDataService.getCompanyMainInfo, uuid);
    typeof callBack === 'function' && callBack();

    yield put(actions.fetchCompanyInfo.success({ data: data.data }));
  } catch (err) {
    yield put(actions.fetchCompanyInfo.failure(err));
  }
}

export function* companyInfoSaga() {
  yield all([yield takeLatest(actions.fetchCompanyInfo.TRIGGER, fetchCompanyInfoFromServer)]);
}
