import { takeLatest, call, put, all } from 'redux-saga/effects';
import CompanyLead from '@services/storeLead';
import ErrorParser from 'utils/redux-helper/errorParser';
import actions from './actions';

export function* postStoreLeadFromServer(action) {
  const errorParser = new ErrorParser();
  const { callBack, ...payload } = action.payload;
  try {
    const { data } = yield call(CompanyLead.storeCompanyLead, payload);

    if (data && typeof callBack === 'function') {
      callBack(Promise.resolve(data));
    }

    yield put(actions.postStoreLead.success({ data }));
  } catch (err) {
    if (err && typeof callBack === 'function') {
      callBack(Promise.reject(errorParser.parseErrorMessaage(err)));
    }

    yield put(actions.postStoreLead.failure(err));
  }
}

export function* postStoreLeadSaga() {
  yield all([yield takeLatest(actions.postStoreLead.TRIGGER, postStoreLeadFromServer)]);
}
