import { ReduxRoutines } from '@utils/redux-helper';
import { opportunitiesActions as actions } from './actions';

const initialState = {
  errorMessage: '',
  isLoading: false,
  opportunities: {},
};

export const opportunities = (state = initialState, { type, payload }) => {
  const redux = new ReduxRoutines(state);

  switch (type) {
    case actions.getOpportunities.TRIGGER:
      return redux.trigger();
    case actions.getOpportunities.SUCCESS:
      return redux.success(payload);
    case actions.getOpportunities.FAILURE:
      return redux.fail(payload);
    default:
      return { ...state };
  }
};
