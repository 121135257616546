import { call, put } from 'redux-saga/effects';
import servicesAPI from '@services/services';
import { servicesActions } from '../../actions';

export function* publishService(action) {
  try {
    yield put(servicesActions.publishService.request());
    const { data } = yield call(servicesAPI.publish, action.payload);
    yield put(servicesActions.publishService.success({ status: data.data.status, published: data.data.published }));
  } catch (err) {
    console.log(err);
    yield put(servicesActions.publishService.failure(err));
  }
}
