import {GTM_ID} from "@src/constants/global";
import {getCookie, setCookie} from '@utils/cookie';

const UTM_ITEM_LIST = [
  "utm_source",
  "utm_medium",
  "utm_term",
  "utm_content",
  "utm_campaign",
  "matchtype",
  "device",
  "keyword",
  "adgroupid",
  "campaignid",
  "region",
  "_openstat",
  "gclid",
  "yclid",
  "fbclid",
  "wbraid",
  "gad",
  "utm_region",
  "utm_placement",
  "utm_device"
];
const REFERRER_LIST = [
  "google",
  "yandex",
  "rambler",
  "bing",
  "mail",
  "duckduckgo",
  "instagram",
  "facebook",
  "vk.com",
  "pinterest",
  "linkedin",
  "ok.ru",
  "youtube",
  "tiktok",
  "rutube"
]
const FIELDS_FOR_REPLACE_MAP = {
  "_openstat": "openstat",
  "wbraid": "gclid"
}

export const pageview = (url) => {
  if(window.gtag) {
    window.gtag('config', GTM_ID, {
      page_path: url,
    })
  }
  if(window.dataLayer){
    window.dataLayer.push({
      event: 'pageview',
      page: url,
    })
  }
}

// log specific events happening.
export  const event = ({ action, params }) => {
  if(window.gtag) {
    window.gtag('event', action, params)
  }
}

export  const getGaClientId = () => getCookie('_ga');

export const setUTM = () => {
  let params = new URLSearchParams(window.location.search);
  for (const [key, value] of params) {
    if (UTM_ITEM_LIST.includes(key)) setCookie(key, value, { path: '/'});
  }

  if (!getCookie("utm_source")) {
    const curReferer = document.referrer;
    if (curReferer.length > 0) {
      REFERRER_LIST.forEach(i => {
        if (curReferer.includes(i)) {
          setCookie("utm_source", i, { path: '/'});
          setCookie("utm_medium", "seo", { path: '/'});
        }
      })
      setCookie("utm_campaign", "organic", {path: '/'});
      setCookie("utm_content", document.location.href.slice(0, 90), {path: '/'});
    }
  }
};

export const getUTM = () => {
  const result = {};
  UTM_ITEM_LIST.forEach((name) => {
    if (getCookie(name)) {
      result[FIELDS_FOR_REPLACE_MAP[name] || name] = getCookie(name);
    }
  });
  return result;
};
