import { ReduxRoutines } from 'utils/redux-helper';
import { HYDRATE } from 'next-redux-wrapper';
import omit from 'lodash/omit'
import actions from './actions';

const initialState = {
  advertisement: [],
  base_search: [],
  alternative: [],
  seo_filter: {},
  slug_params: {},
  current_page: null,
  last_page: null,
  per_page: null,
  total: null,
  isShowMore: false,
  isLoading: false,
  errorMessage: '',
  companies_count: '',
};
const searchCompanies = (state = initialState, { type, payload }) => {
  const redux = new ReduxRoutines(state);
  switch (type) {
    case HYDRATE: {
      const { searchCompanies } = payload;
      const searchCompaniesState = omit(searchCompanies, ['slug_params', 'seo_filter']);
      return { ...state, ...searchCompaniesState };
    }
    case actions.fetchAllCompanies.CLEAR:
      return redux.clear(initialState);
    case actions.fetchAllCompanies.TRIGGER:
      return redux.trigger();
    case actions.fetchAllCompanies.SUCCESS:
      return redux.success(payload);
    case actions.fetchAllCompanies.FAILURE:
      return redux.fail(payload);

    case actions.getFilterCompaniesCount.TRIGGER:
      return redux.trigger();
    case actions.getFilterCompaniesCount.SUCCESS:
      return redux.success(payload);
    case actions.getFilterCompaniesCount.FAILURE:
      return redux.fail(payload);

    case actions.getSeoSlugSearchParams.TRIGGER:
      return redux.trigger();
    case actions.getSeoSlugSearchParams.SUCCESS:
      return redux.success(payload);
    case actions.getSeoSlugSearchParams.FAILURE:
      return redux.fail(payload);

    case actions.loadMoreCompanies.TRIGGER:
      return { ...state, isShowMore: true };
    case actions.loadMoreCompanies.SUCCESS: {
      const newList = payload.base_search;
      const { base_search, alternative } = state;
      const loadMoreParams = {
        ...state,
        base_search: [...base_search, ...newList],
        current_page: payload.current_page,
        last_page: payload.last_page,
        isShowMore: false,
      };
      !!payload.alternative && (loadMoreParams.alternative = [...alternative, ...payload.alternative]);
      return loadMoreParams;
    }
    case actions.loadMoreCompanies.FAILURE:
      return redux.fail(payload, { isShowMore: false });
    default:
      return { ...state };
  }
};

export { searchCompanies };
