import { ReduxRoutines } from '@utils/redux-helper';
import { HYDRATE } from 'next-redux-wrapper';
import actions from './actions';

const initialState = {
  data: {},
  isLoading: true,
  errorMessage: '',
};
const searchInit = (state = initialState, { type, payload }) => {
  const redux = new ReduxRoutines(state);
  switch (type) {
    case HYDRATE: {
      const { searchInit } = payload;
      return { ...state, ...searchInit };
    }
    case actions.fetchSearchInit.TRIGGER:
      return redux.trigger();
    case actions.fetchSearchInit.SUCCESS:
      return redux.success(payload);
    case actions.fetchSearchInit.FAILURE:
      return redux.fail(payload);
    default:
      return { ...state };
  }
};

export { searchInit };
