import { takeLatest, call, put, all } from 'redux-saga/effects';
import CompanyDataService from '@services/companyDataService';
import actions from './actions';

export function* fetchServiceDataPreview(e) {
  try {
    const { data } = yield call(CompanyDataService.serviceDataPreview, e.payload);
    const {
      uuid,
      name,
      price_from,
      price_to,
      currency,
      duration,
      time_unit,
      show_price_block,
      show_duration_block,
      show_documents_block,
      show_required_steps_block,
      show_benefits_block,
      receiving_reason,
      background_image,
      country,
      purposes,
      applicant_requirements,
      documents,
      required_steps,
      benefits,
      feedback_forms,
    } = data.data;

    yield put(
      actions.serviceDataPreview.success({
        uuid,
        name,
        price_from,
        price_to,
        currency,
        duration,
        time_unit,
        show_price_block,
        show_duration_block,
        show_documents_block,
        show_required_steps_block,
        show_benefits_block,
        receiving_reason,
        background_image,
        country,
        purposes,
        applicant_requirements,
        documents,
        required_steps,
        benefits,
        feedback_forms,
      }),
    );
  } catch (err) {
    yield put(actions.serviceDataPreview.failure(err));
  }
}

export default function* serviceDataPreviewSaga() {
  yield all([yield takeLatest(actions.serviceDataPreview.TRIGGER, fetchServiceDataPreview)]);
}
