import { ReduxRoutines } from '@utils/redux-helper';
import actions from './actions';

const initialState = {
  name: '',
  motto: '',
  description: '',
  show_achievements_block: true,
  show_benefits_block: true,
  show_payment_block: true,
  logo: null,
  logo_uuid: null,
  company_payment_methods: [],
  company_achievements: [],
  feedback_form: { description: '', feedback_form_type: { id: null } },
  company_benefits: [],
  company_offices: [
    {
      address: '',
      email: '',
      logo: '',
      logo_uuid: '',
      type: '',
      show_block: true,
      office_type: { id: null },
      long: '',
      lat: '',
      office_working_times: [
        {
          hours: '',
          days: '',
          hour_from: '',
          hour_to: '',
        },
      ],
      office_holidays: [
        {
          hours: '',
          days: '',
        },
      ],
      office_employees: [],
      phones: [
        {
          phone_number: '',
          messengers: [],
        },
      ],
    },
  ],
  langInHeader: '',
  filled_locales: [],
  isLoading: false,
};

function getCompanyData(state = initialState, { type, payload }) {
  const redux = new ReduxRoutines(state);
  switch (type) {
    case actions.getCompanyData.CLEAR:
      return redux.clear({ ...initialState, ...payload });
    case actions.getCompanyData.TRIGGER:
    case actions.storeCompanyData.TRIGGER:
    case actions.updateCompanyData.TRIGGER:
    case actions.publishCompany.TRIGGER:
    case actions.unPublishCompany.TRIGGER:
    case actions.changeLangVersionCompany.TRIGGER:
    case actions.getLangVersionCompany.TRIGGER:
      return redux.trigger();
    case actions.getCompanyData.SUCCESS:
    case actions.storeCompanyData.SUCCESS:
    case actions.updateCompanyData.SUCCESS:
    case actions.publishCompany.SUCCESS:
    case actions.unPublishCompany.SUCCESS:
    case actions.changeLangVersionCompany.SUCCESS:
    case actions.getLangVersionCompany.SUCCESS:
      return redux.success(payload);
    case actions.getCompanyData.FAILURE:
    case actions.storeCompanyData.FAILURE:
    case actions.updateCompanyData.FAILURE:
    case actions.publishCompany.FAILURE:
    case actions.unPublishCompany.FAILURE:
    case actions.changeLangVersionCompany.FAILURE:
    case actions.getLangVersionCompany.FAILURE:
      return redux.fail(payload);
    default:
      return { ...state };
  }
}

export default getCompanyData;
