import API from './API';

const API_PREFIX = '/v1/account';

class Advertisement extends API {
  getAdvertisementInit = (tokenStr) => this.get(`${API_PREFIX}/advertisement/init`, tokenStr);

  postCalculatePriceAdvertisement = ({ data, token }) =>
    this.post(`${API_PREFIX}/advertisement/calculate-total-price`, data, token);

  postCreateAdvertisement = ({ data, token }) => this.post(`${API_PREFIX}/company/advertisement`, data, token);

  getAllCompanyAdvertisement = (query) => this.get(`${API_PREFIX}/company/advertisements`, '', query);
}

export default new Advertisement();
