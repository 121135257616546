export const initialServiceValues = {
  aboutServiceBlock: {
    name: '',
    price_from: null,
    price_to: null,
    currency_id: null,
    show_price_block: true,
    duration: null,
    time_unit_id: null,
    show_duration_block: true,
    background_image_id: null,
  },
  receiving_reason: '',
  applicant_requirements: [],
  serviceDocumentsBlock: {
    service_documents: [],
    show_documents_block: true,
  },
  serviceRequiredStepsBlock: {
    show_required_steps_block: true,
    service_required_steps: [
      {
        name: '',
        description: '',
        icon_id: null,
        icon_url: null,
      },
      {
        name: '',
        description: '',
        icon_id: null,
        icon_url: null,
      },
    ],
  },
  serviceBenefitsBlock: {
    show_benefits_block: true,
    service_benefits: [
      { name: '', description: '', icon_id: null },
      { name: '', description: '', icon_id: null },
      { name: '', description: '', icon_id: null },
    ],
  },
};

export const initialServiceAttributesValues = {
  name: '',
  price_from: null,
  price_to: null,
  currency_id: null,
  duration: null,
  time_unit_id: null,
  show_price_block: true,
  show_duration_block: true,
  country: null,
  countries: [],
  immigration_purposes: [],
  categories: [],
  main_category: null,
};
