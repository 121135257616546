import { ReduxRoutines } from '@utils/redux-helper';
import actions from './actions';

const initialState = {
  visa_map: [],
  visa_statuses: [],
  companies_count: null,
  isLoading: true,
  errorMessage: ''
};

const getVisaMap = (state = initialState, { type, payload }) => {
  const redux = new ReduxRoutines(state);
  switch (type) {
    case actions.getVisaMap.CLEAR:
      return redux.clear(initialState);
    case actions.getVisaMap.TRIGGER:
      return redux.trigger();
    case actions.getVisaMap.SUCCESS:
      return redux.success(payload);
    case actions.getVisaMap.FAILURE:
      return redux.fail(payload);
    default:
      return { ...state };
  }
};

export { getVisaMap };
