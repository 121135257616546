import { createSelector } from 'reselect';

const selectCompanyServiceCategories = (state) => state.companyServiceCategories.data;
const selectError = (state) => state.companyServiceCategories.errMessage;
const selectIsLoading = (state) => state.companyServiceCategories.isLoad;

const loadingAndErrors = () =>
  createSelector(selectIsLoading, selectError, (isLoad, errMessage) => ({
    isLoad,
    errMessage,
  }));

export { selectCompanyServiceCategories, selectIsLoading, selectError, loadingAndErrors };
