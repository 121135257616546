import { takeLatest, call, put, all } from 'redux-saga/effects';
import Countries from '@services/Countries';
import actions from './actions';

export function* fetchAllCountriesFromServer(e) {
  const { langInHeader, ...payload } = e.payload || { langInHeader: '' };
  try {
    const { data } = yield call(Countries.getAllCountries, payload, langInHeader);
    yield put(
      actions.fetchAllCountries.success({
        data,
      }),
    );
  } catch (err) {
    yield put(actions.fetchAllCountries.failure(err));
  }
}

export function* getAllCountriesSaga() {
  yield all([yield takeLatest(actions.fetchAllCountries.TRIGGER, fetchAllCountriesFromServer)]);
}
