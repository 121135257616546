import { ReduxRoutines } from '@utils/redux-helper';
import { companyStatisticActions as actions } from './actions';

const initialState = {
  item: {},
  errorMessage: '',
  isLoading: true,
};

export const companyStatistic = (state = initialState, { type, payload }) => {
  const redux = new ReduxRoutines(state);

  switch (type) {
    case actions.companyStatistic.REQUEST:
      return redux.trigger();
    case actions.companyStatistic.SUCCESS:
      return redux.success(payload);
    case actions.companyStatistic.FAILURE:
      return redux.fail(payload);
    default:
      return { ...state };
  }
};
