import API from './API';

class Profile extends API {
  profileData = (tokenStr) => this.get('/v1/auth/profile', tokenStr);

  profileUpdate = ({ data, token }) => this.put('/v1/auth/profile', data, token);

  changePassword = ({ data, token }) => this.post('/v1/auth/change-password', data, token);

  uploadAvatar = ({ data, token }) => this.post('/v1/auth/avatar', data, token);
}

export default new Profile();
