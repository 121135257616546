import { call, put } from 'redux-saga/effects';
import servicesAPI from '@services/services';
import { checkServiceValue } from 'utils/checkServiceValue';
import { servicesActions } from '../../actions';

export function* getService(action) {
  try {
    yield put(servicesActions.getService.request());

    const { data } = yield call(servicesAPI.getService, action.payload);
    yield put(
      servicesActions.getService.success({
        initialServiceValues: {
          aboutServiceBlock: {
            name: data.data?.name || '',
            price_from: checkServiceValue(data.data?.price_from),
            price_to: checkServiceValue(data.data?.price_to),
            currency_id: data.data?.currency?.id,
            show_price_block: data.data?.show_price_block,
            duration: checkServiceValue(data.data?.duration),
            time_unit_id: data.data?.time_unit?.id,
            show_duration_block: data.data?.show_duration_block,
            background_image_id: data.data?.image_library?.id,
            background_image_url: data.data?.image_library?.image,
          },

          receiving_reason: data.data?.receiving_reason || '',
          applicant_requirements: data.data?.applicant_requirements,
          serviceDocumentsBlock: {
            service_documents: data.data?.service_documents.map((item) => ({
              uuid: item.uuid,
              name: item.name,
              icon_id: item?.image_library?.id,
              icon_url: item?.image_library?.image,
            })),
            show_documents_block: data.data?.show_documents_block,
          },
          serviceRequiredStepsBlock: {
            show_required_steps_block: data.data?.show_required_steps_block,
            service_required_steps: data.data?.service_required_steps.map((item) => ({
              uuid: item.uuid,
              name: item.name || '',
              description: item.description || '',
              icon_id: item?.image_library?.id,
              icon_url: item?.image_library?.image,
            })),
          },
          serviceBenefitsBlock: {
            show_benefits_block: data.data?.show_benefits_block,
            service_benefits: data.data?.service_benefits.map((item) => ({
              uuid: item.uuid,
              name: item.name || '',
              description: item.description || '',
              icon_id: item?.image_library?.id,
              icon_url: item?.image_library?.image,
            })),
          },
          feedback_forms: data.data.feedback_forms.map((item) => ({
            uuid: item.uuid,
            type_id: item?.feedback_form_type?.id,
            description: item.description || '',
            background_image_id: item?.image_library?.id,
            background_image_url: item?.image_library?.image,
          })),
        },
        status: data.data?.status,
        moderator_comment: data.data?.moderator_comment,
        published: data.data?.published,
      }),
    );
  } catch (err) {
    yield put(servicesActions.getService.failure(err));
  }
}
