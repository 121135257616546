import { ReduxRoutines } from 'utils/redux-helper';
import { companyCertificatesActions as actions } from './actions';

const initialState = {
  certificatesList: [],
  isLoading: true,
  errorMessage: '',
  certificateDeliveryStatus: {},
};

export const companyCertificates = (state = initialState, { type, payload }) => {
  const redux = new ReduxRoutines(state);
  switch (type) {
    case actions.orderCertificateDelivery.CLEAR:
      return redux.clear(initialState);
    case actions.getCompanyCertificates.REQUEST:
    case actions.getCertificatePdf.TRIGGER:
    case actions.orderCertificateDelivery.TRIGGER:
      return redux.trigger();
    case actions.getCompanyCertificates.SUCCESS:
    case actions.getCertificatePdf.SUCCESS:
    case actions.orderCertificateDelivery.SUCCESS:
      return redux.success(payload);
    case actions.getCompanyCertificates.FAILURE:
    case actions.getCertificatePdf.FAILURE:
    case actions.orderCertificateDelivery.FAILURE:
      return redux.fail(payload);
    default:
      return { ...state };
  }
};
