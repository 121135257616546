import { takeLatest, call, put, all } from 'redux-saga/effects';
import SearchCompanies from 'services/searchCompanies';
import { filterSlugParams } from 'utils/filterSlugParams';
import actions from './actions';

export function* fetchAllCompaniesFromServer({ payload }) {
  try {
    yield put(actions.fetchAllCompanies.request());
    const { data } = yield call(SearchCompanies.getAllCompanies, payload);
    yield put(
      actions.fetchAllCompanies.success({
        alternative: data?.data?.alternative,
        advertisement: data?.data?.advertisement,
        base_search: data?.data?.base_search,
        seo_filter: data?.data?.seo_filter || {},
        current_page: data?.meta?.current_page,
        last_page: data?.meta?.last_page,
        per_page: data?.meta?.per_page,
        total: data?.meta?.total,
      }),
    );
  } catch (err) {
    yield put(actions.fetchAllCompanies.failure(err));
  }
}
export function* loadMoreCompaniesFromServer(action) {
  try {
    const { data } = yield call(SearchCompanies.loadMoreCompanies, action.payload);
    yield put(
      actions.loadMoreCompanies.success({
        base_search: data?.data?.base_search,
        alternative: data?.data?.alternative,
        current_page: data?.meta?.current_page,
        last_page: data?.meta?.last_page,
      }),
    );
  } catch (err) {
    yield put(actions.loadMoreCompanies.failure(err));
  }
}

export function* getFilterCompaniesCount({ payload }) {
  try {
    const { data } = yield call(SearchCompanies.getFilterCompaniesCount, payload);
    yield put(
      actions.getFilterCompaniesCount.success({
        companies_count: data?.total,
      }),
    );
  } catch (err) {
    yield put(actions.getFilterCompaniesCount.failure(err));
  }
}

export function* getSeoSlugSearchParams({ payload }) {
  const { path, langInHeader, currencyInHeader } = payload;
  let query = {};
  try {
    const { data } = yield call(SearchCompanies.getSeoSlugSearchParams, { path, langInHeader });
    const slugParams = data.data;
    query = filterSlugParams(slugParams);
  } catch (err) {
    query = undefined;
  }
  try {
    const { data } = yield call(SearchCompanies.getAllCompanies, { query, langInHeader, currencyInHeader });
    const { seo_filter, base_search, advertisement, alternative } = data?.data;
    const { current_page, last_page, per_page, total } = data?.meta;
    yield put(
      actions.fetchAllCompanies.success({
        alternative,
        advertisement,
        base_search,
        seo_filter: seo_filter || {},
        current_page,
        last_page,
        per_page,
        total,
      }),
    );
  } catch (err) {
    yield put(actions.getSeoSlugSearchParams.failure(err));
  }
}

export function* getAllCompaniesSaga() {
  yield all([
    yield takeLatest(actions.fetchAllCompanies.TRIGGER, fetchAllCompaniesFromServer),
    yield takeLatest(actions.loadMoreCompanies.TRIGGER, loadMoreCompaniesFromServer),
    yield takeLatest(actions.getSeoSlugSearchParams.TRIGGER, getSeoSlugSearchParams),
    yield takeLatest(actions.getFilterCompaniesCount.TRIGGER, getFilterCompaniesCount),
  ]);
}
