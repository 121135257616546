import { takeEvery, call, all } from 'redux-saga/effects';
import { visaMapCountriesActions as actions } from '../actions';
import { getVisaMapCountries } from './workers';

function* watchGetVisaMapCountries() {
  yield takeEvery(actions.getVisaMapCountries.TRIGGER, getVisaMapCountries);
}

export function* visaMapCountriesWatchers() {
  yield all([call(watchGetVisaMapCountries)]);
}
