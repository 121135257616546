import { takeLatest, call, put, all } from 'redux-saga/effects';
import CompanyDataService from '@services/companyDataService';
import actions from './actions';

export function* fetchCompanyServiceCategoriesFromServer() {
  try {
    // perform request to test promise to fetch some data
    const { data } = yield call(CompanyDataService.getCompanyServiceCategories);

    // if request successfully finished
    yield put(actions.fetchCompanyServiceCategories.success({ data }));
  } catch (error) {
    // if request failed
    yield put(actions.fetchCompanyServiceCategories.failure(error));
  }
}
export function* companyServiceCategoriesSaga() {
  // run fetchCompanyServiceCategoriesFromServer on every trigger action
  yield all([yield takeLatest(actions.fetchCompanyServiceCategories.TRIGGER, fetchCompanyServiceCategoriesFromServer)]);
}
