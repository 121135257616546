import { takeEvery, all, call } from 'redux-saga/effects';
import { postContactsActions as actions } from '../actions';
import { postContacts } from './workers';

function* postContactsWatcher() {
  yield takeEvery(actions.postContacts.TRIGGER, postContacts);
}

export function* postContactsWatchers() {
  yield all([call(postContactsWatcher)]);
}
