import { takeEvery, call, put, all } from 'redux-saga/effects';
import OrderCertificate from 'services/OrderCertificate';
import actions from './actions';

export function* storeCertificateS(action) {
  try {
    const { data } = yield call(OrderCertificate.storeCertificate, action.payload);
    yield put(actions.storeCertificate.success({ backendResult: data.data }));
  } catch (err) {
    // if request failed
    yield put(actions.storeCertificate.failure(err));
  }
}

export function* storeCertificateSaga() {
  yield all([yield takeEvery(actions.storeCertificate.TRIGGER, storeCertificateS)]);
}
