import API from './API';

class AuthService extends API {
  login = (data) => this.post('/v1/auth/signin', data);

  register = (data) => this.post('/v1/auth/signup', data);

  activateEmail = (data) => this.post(`/v1/auth/email-activation/${data}`);

  sendActivationEmail = (token) => this.post(`/v1/auth/send-activation-email`, {}, token);

  sendResetPasswordEmail = ({ email, langInHeader }) => this.post('/v1/auth/send-email', { email }, '', langInHeader);

  resetPasswordCheckCode = (code) => this.get(`/v1/auth/check-code/${code}`);

  resetPassword = ({ data, code }) => this.post(`/v1/auth/reset/${code}`, data);

  socialLogin = (data) => this.post('/v1/auth/social/login', data);

  socialSignUp = (data) => this.post('/v1/auth/social/signup', data);

  // old requests
  sendEmail = (data) => this.post('/auth/send-email', data);

  restore = (data) => this.post('/auth/reset', data);

  checkCode = (query) => this.get(`/auth/check-code?code=${query}`);

  activate = (data) => this.post('/auth/activate', data);
}

export default new AuthService();
