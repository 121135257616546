import { takeLatest, call, put, all } from 'redux-saga/effects';
import Subscription from '@services/Subscription';
import actions from './actions';

export function* buySubscriptionFromServer(action) {
  try {
    const { data } = yield call(Subscription.buySubscription, action.payload);
    // if request successfully finished
    yield put(actions.buySubscription.success({ data: data.data }));
    window.location.href = data.data.url;
  } catch (err) {
    // if request failed
    yield put(actions.buySubscription.failure(err));
  }
}

export function* buySubscriptionSaga() {
  yield all([yield takeLatest(actions.buySubscription.TRIGGER, buySubscriptionFromServer)]);
}
