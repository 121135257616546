import { call, put } from 'redux-saga/effects';
import servicesAPI from '@services/services';
import { checkServiceValue } from 'utils/checkServiceValue';
import { servicesActions } from '../../actions';

export function* getServiceAttributes(action) {
  try {
    yield put(servicesActions.getServiceAttributes.request());

    const { data } = yield call(servicesAPI.getServiceAttributes, action.payload);
    const {
      uuid,
      name,
      price_from,
      price_to,
      currency,
      duration,
      time_unit,
      show_price_block,
      show_duration_block,
      country,
      countries,
      immigration_purposes,
      categories,
      main_category,
      status,
      published,
    } = data.data;
    yield put(
      servicesActions.getServiceAttributes.success({
        initialServiceAttributesValues: {
          uuid,
          name,
          price_from: checkServiceValue(price_from),
          price_to: checkServiceValue(price_to),
          currency_id: currency?.id,
          duration: checkServiceValue(duration),
          time_unit_id: time_unit?.id,
          show_price_block,
          show_duration_block,
          country: country?.id,
          countries: countries?.map(({ id }) => id),
          immigration_purposes: immigration_purposes?.map(({ id }) => id),
          categories: categories?.map(({ id }) => id),
          main_category: main_category?.id,
        },
        status,
        published,
      }),
    );
  } catch (err) {
    yield put(servicesActions.getServiceAttributes.failure(err));
  }
}
