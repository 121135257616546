import { ReduxRoutines } from '@utils/redux-helper';
import { visaMapCountriesActions as actions } from './actions';

const initialState = {
  country_groups: [],
  countries: [],
  errorMessage: '',
  isLoading: true,
};

export const visaMapCountries = (state = initialState, { type, payload }) => {
  const redux = new ReduxRoutines(state);

  switch (type) {
    case actions.getVisaMapCountries.TRIGGER:
      return redux.trigger(payload);

    case actions.getVisaMapCountries.SUCCESS:
      return redux.success(payload);

    case actions.getVisaMapCountries.FAILURE:
      return redux.fail(payload);
    default:
      return { ...state };
  }
};
