import { takeLatest, call, put, all } from 'redux-saga/effects';
import Appeals from 'services/appeals';
import actions from './actions';

export function* getUserLeadsFromServer({ payload }) {

  try {
    const { data } = yield call(Appeals.getUserLeads, payload);

    const { query } = payload;
    const { last_page } = data?.meta;
    const  isPageValid = last_page < query?.page;

    const lastPageData =  isPageValid &&
    ( yield call(Appeals.getUserLeads, { ...payload, query: { ...query, page: last_page} }));

    const leadsList = isPageValid ? lastPageData?.data : data ;

    yield put(actions.getUserLeads.success({ data: leadsList?.data, meta: leadsList?.meta }));
  } catch (err) {
    // if request failed
    yield put(actions.getUserLeads.failure(err));
  }
}

export function* getUserLeadsSaga() {
  yield all([yield takeLatest(actions.getUserLeads.TRIGGER, getUserLeadsFromServer)]);
}
