import { SEO_SLUG_LIST } from './actions';

const initialState = {
  data: {},
  isLoading: true,
  errorMessage: '',
};

export const seoSlugListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SEO_SLUG_LIST:
      return { ...state, data: payload };
    default:
      return state;
  }
};
