import { takeLatest, call, put, all } from 'redux-saga/effects';
import CompanyReviews from 'services/companyReviews';
import actions from './actions';

export function* getCompanyReviewsFromServer(action) {
  try {
    const { data } = yield call(CompanyReviews.getCompanyReviews, action.payload);
    // if request successfully finished
    yield put(
      actions.getCompanyReviews.success({
        data: data.data,
        total: data.meta.total,
        current_page: data.meta.current_page,
        last_page: data.meta.last_page,
        per_page: data.meta.per_page,
      }),
    );
  } catch (err) {
    // if request failedss
    yield put(actions.getCompanyReviews.failure(err));
  }
}

export function* loadMoreCompanyReviewsFromServer(action) {
  try {
    const { data } = yield call(CompanyReviews.loadMoreCompanyReviews, action.payload);
    const { reviews } = data.data;
    // if request successfully finished
    yield put(
      actions.loadMoreCompanyReviews.success({
        data: reviews,
        current_page: data.meta.current_page,
        last_page: data.meta.last_page,
      }),
    );
  } catch (err) {
    // if request failed
    yield put(actions.loadMoreCompanyReviews.failure(err));
  }
}
export function* createCompanyReviewFromServer(action) {
  try {
    const { data } = yield call(CompanyReviews.createCompanyReview, action.payload);
    // if request successfully finished
    // const { reviews } = data.data;
    yield put(actions.createCompanyReview.success({ data: data.data }));
  } catch (err) {
    // if request failed
    yield put(actions.createCompanyReview.failure(err));
  }
}
export function* deleteCompanyReviewFromServer(action) {
  try {
    yield call(CompanyReviews.deleteCompanyReview, action.payload);
    // if request successfully finished
    yield put(actions.deleteCompanyReview.success(action.payload.id));
  } catch (err) {
    // if request failed
    yield put(actions.deleteCompanyReview.failure(err));
  }
}
export function* updateCompanyReviewFromServer(action) {
  try {
    const { data } = yield call(CompanyReviews.updateCompanyReview, action.payload);
    // if request successfully finished
    yield put(actions.updateCompanyReview.success({ data: data.data }));
  } catch (err) {
    // if request failed
    yield put(actions.updateCompanyReview.failure(err));
  }
}
export function* createReviewCommentFromServer(action) {
  try {
    const { data } = yield call(CompanyReviews.createReviewComment, action.payload);
    const { review_id } = action.payload.data;
    // if request successfully finished
    // const { reviews } = data.data;
    yield put(actions.createReviewComment.success({ data: data.data, review_id }));
  } catch (err) {
    // if request failed
    yield put(actions.createReviewComment.failure(err));
  }
}
export function* deleteReviewCommentFromServer(action) {
  try {
    yield call(CompanyReviews.deleteReviewComment, action.payload);

    // if request successfully finished
    yield put(actions.deleteReviewComment.success(action.payload));
  } catch (err) {
    // if request failed
    yield put(actions.deleteReviewComment.failure(err));
  }
}
export function* updateReviewCommentFromServer(action) {
  try {
    const { data } = yield call(CompanyReviews.updateReviewComment, action.payload);
    // if request successfully finished
    yield put(
      actions.updateReviewComment.success({
        data: data.data,
        id: action.payload.id,
        review_id: action.payload.review_id,
      }),
    );
  } catch (err) {
    // if request failed
    yield put(actions.updateReviewComment.failure(err));
  }
}
export function* addReviewLikeFromServer(action) {
  try {
    const { data } = yield call(CompanyReviews.addReviewLike, action.payload);
    // if request successfully finished
    yield put(actions.addReviewLike.success({ data: data.data, id: action.payload.id }));
  } catch (err) {
    // if request failed
    yield put(actions.addReviewLike.failure(err));
  }
}
export function* addCommentLikeFromServer(action) {
  try {
    const { data } = yield call(CompanyReviews.addCommentLike, action.payload);
    // if request successfully finished
    yield put(
      actions.addCommentLike.success({
        data: data.data,
        id: action.payload.id,
        review_id: action.payload.review_id,
      }),
    );
  } catch (err) {
    // if request failed
    yield put(actions.addCommentLike.failure(err));
  }
}
export function* getAuthorReviewsFromServer(action) {
  try {
    const { data } = yield call(CompanyReviews.getAuthorReviews, action.payload);
    // if request successfully finished
    yield put(
      actions.getAuthorReviews.success({
        data: data.data,
        total: data.meta.total,
        current_page: data.meta.current_page,
        last_page: data.meta.last_page,
        per_page: data.meta.per_page,
      }),
    );
  } catch (err) {
    // if request failed
    yield put(actions.getAuthorReviews.failure(err));
  }
}
export function* getCompanyAccountReviewsFromServer(action) {
  try {
    const { data } = yield call(CompanyReviews.getCompanyAccountReviews, action.payload);
    // if request successfully finished
    yield put(
      actions.getCompanyAccountReviews.success({
        data: data.data,
        total: data.meta.total,
        current_page: data.meta.current_page,
        last_page: data.meta.last_page,
        per_page: data.meta.per_page,
      }),
    );
  } catch (err) {
    // if request failedss
    yield put(actions.getCompanyAccountReviews.failure(err));
  }
}

export function* getCompanyLeadUserReviewsFromServer(action) {
  try {
    const { data } = yield call(CompanyReviews.getCompanyLeadUserReviews, action.payload);
    // if request successfully finished
    yield put(
      actions.getCompanyLeadUserReviews.success({
        data: data.data,
        total: data.meta.total,
        current_page: data.meta.current_page,
        last_page: data.meta.last_page,
        per_page: data.meta.per_page,
      }),
    );
  } catch (err) {
    // if request failed
    yield put(actions.getCompanyLeadUserReviews.failure(err));
  }
}

export function* getCompanyReviewsSaga() {
  yield all([
    yield takeLatest(actions.getCompanyReviews.TRIGGER, getCompanyReviewsFromServer),
    yield takeLatest(actions.loadMoreCompanyReviews.TRIGGER, loadMoreCompanyReviewsFromServer),
    yield takeLatest(actions.createCompanyReview.TRIGGER, createCompanyReviewFromServer),
    yield takeLatest(actions.deleteCompanyReview.TRIGGER, deleteCompanyReviewFromServer),
    yield takeLatest(actions.updateCompanyReview.TRIGGER, updateCompanyReviewFromServer),
    yield takeLatest(actions.createReviewComment.TRIGGER, createReviewCommentFromServer),
    yield takeLatest(actions.deleteReviewComment.TRIGGER, deleteReviewCommentFromServer),
    yield takeLatest(actions.updateReviewComment.TRIGGER, updateReviewCommentFromServer),
    yield takeLatest(actions.addReviewLike.TRIGGER, addReviewLikeFromServer),
    yield takeLatest(actions.addCommentLike.TRIGGER, addCommentLikeFromServer),
    yield takeLatest(actions.getAuthorReviews.TRIGGER, getAuthorReviewsFromServer),
    yield takeLatest(actions.getCompanyAccountReviews.TRIGGER, getCompanyAccountReviewsFromServer),
    yield takeLatest(actions.getCompanyLeadUserReviews.TRIGGER, getCompanyLeadUserReviewsFromServer),
  ]);
}
