import { ReduxRoutines } from '@utils/redux-helper';
import actions from './actions';

const initialState = {
  uuid: '',
  name: '',
  price_from: '',
  price_to: '',
  currency: '',
  duration: '',
  time_unit: '',
  show_price_block: true,
  show_duration_block: true,
  show_documents_block: true,
  show_required_steps_block: true,
  receiving_reason: '',
  background_image: '',
  country: '',
  purposes: [],
  applicant_requirements: [],
  documents: [],
  required_steps: [],
  benefits: [],
  feedback_forms: [],
};

function serviceDataPreview(state = initialState, { type, payload }) {
  const redux = new ReduxRoutines(state);
  switch (type) {
    case actions.serviceDataPreview.CLEAR:
      return redux.clear(initialState);
    case actions.serviceDataPreview.TRIGGER:
      return redux.trigger();
    case actions.serviceDataPreview.SUCCESS:
      return redux.success(payload);
    case actions.serviceDataPreview.FAILURE:
      return redux.fail(payload);
    default:
      return { ...state };
  }
}

export default serviceDataPreview;
