export const IS_BROWSER = typeof window !== 'undefined';
export const IS_SERVER = typeof window === 'undefined';

export const GTM_ID = 'GTM-TTMDL53';
export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_CURRENCY = 'EUR';
export const DEFAULT_LOCALE = 'en';
export const DOMAIN = 'https://iworld.com';

// COUNTRIES PASS IMAGES

export const PAGE_SIZE = 10;
export const PAGE_START = 1;

// SHARE LINK
export const TELEGRAM = 'Telegram';

// COLOR AVATAR
export const AVATAR_COLOR = {
  1: '#3570C9',
  2: '#F78764',
  3: '#75AFC1',
  4: '#70A9A1',
  5: '#8195FF',
  6: '#FFCC81',
  7: '#C17575',
  8: '#CFB1B7',
  9: '#BFC1F7',
  10: '#64C2F7',
  11: '#3570C9',
  12: '#F78764',
  13: '#75AFC1',
  14: '#70A9A1',
  15: '#8195FF',
  16: '#FFCC81',
};

// MAIN PATHS
export const ADMIN_PATH = 'admin';

// ROLES
export const ROLE_COMPANY = 'company';
export const ROLE_CLIENT = 'client';
export const ROLE_ADMIN = 'admin';
export const ROLE_MOREDATOR = 'moderator';
export const ROLE_CONTENT_MANAGER = 'content-manager';
export const ROLE_ACCOUNT_MANAGER = 'account-manager';

// CREATE COMPANY AND SERVICE
export const BACKGROUND_ICON_DEFAULT = '/img/defaultIconService.svg';
export const LIST_LANGUAGES_FOR_CONSTRUSTOR = [
  {
    name: 'russianModules',
    code: 'ru',
    lng: 'ru',
    order: 0,
  },
  {
    name: 'englishModules',
    code: 'gb',
    lng: 'en',
    order: 1,
  },
];

export const REJECTED = 'rejected';
export const APPROVED = 'approved';
export const CERTIFICATION_PRICE = '700€';

// COUNTRIES PASS IMAGES
export const COMPANY_PAGE_TYPE = 'company';
export const SERVICE_PAGE_TYPE = 'service';
export const SEARCH_PAGE_TYPE = 'search';

//MESSAGES
export const APPEALS_KEY = 'Обращения';
export const MESSAGES_KEY = 'Сообщения';

// ERROR CODES
export const ERROR_CODE_FOR_FORMS = 422;

export const LIST_DAYS = [
  { name: 'monday', id: 0 },
  { name: 'tuesday', id: 1 },
  { name: 'wednesday', id: 2 },
  { name: 'thursday', id: 3 },
  { name: 'friday', id: 4 },
  { name: 'saturday', id: 5 },
  { name: 'sunday', id: 6 },
];

export const HEAD_COMPANY_NAME = 'iWorld';
export const SUPPORT_MAIL = 'support@iworld.com';
export const PARTNERS_MAIL = 'partners@iworld.com';
export const INFO_MAIL = 'info@iworld.com';
export const COOPERATION_MAIL = 'cooperation@iworld.com';
export const ADVERTISING_MAIL = 'advertising@iworld.com';
export const G_MANAGER_MAIL = 'g.manager@iworld.com';
export const CONTACT_PHONE = '971 52 68 93 800';
