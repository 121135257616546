import { call, put } from 'redux-saga/effects';
import VisaMap from 'services/visaMap';
import { visaMapCountriesActions as actions } from '../../actions';

export function* getVisaMapCountries(action) {
  try {
    const { data } = yield call(VisaMap.getVisaMapCountries, action.payload);
    yield put(
      actions.getVisaMapCountries.success({
        country_groups: data.data.country_groups || [],
        countries: data.data.countries || [],
      }),
    );
  } catch (err) {
    yield put(actions.getVisaMapCountries.failure(err));
  }
}
