import API from './API';

const API_BASE = '/v1/account';
const responseType = 'arraybuffer';
class CompanyCertificates extends API {
  getCertificatesList = () => this.get(`${API_BASE}/certificates`);

  getCertificatePdf = (id) => this.get(`${API_BASE}/certificate/${id}/pdf`, '', {}, responseType);

  orderCertificateDelivery = ({ data, id }) => this.post(`${API_BASE}/certificates/${id}/delivery`, data);
}

export default new CompanyCertificates();
