import { ReduxRoutines } from 'utils/redux-helper';
import { layoutStoreActions as actions } from './actions';

const initialState = {
  errorModal: false,
  errorMessage: '',
  errorObject: {},
  errorCode: NaN,
  errorText: '',
  errorTitle: '',
  subscBtnState: false,
  error_code: '',
};

export const layoutStore = (state = initialState, { type, payload }) => {
  const redux = new ReduxRoutines(state);

  switch (type) {
    case actions.errorModalState.CLEAR:
      return redux.clear({ ...initialState });

    case actions.errorModalState.SUCCESS:
      return redux.success(payload);

    case actions.errorModalState.FAILURE:
      return redux.fail(payload);

    default:
      return { ...state };
  }
};
