import { ReduxRoutines } from '@utils/redux-helper';
import { showAndUpdateCertificateActions as actions } from './actions';

const initialState = {
  backendResultUpdateItem: {},
  item: {
    country_id: '',
    legal_form_id: null,
    company_name: '',
    legal_name: '',
    register_number: '',
    address: '',
    activity_type: '',
    contact_person: '',
    contact_phone: '',
    contact_email: '',
    filler_name: '',
    legal_chart: false,
    thanks_letters: false,
    social_activity: false,
    experience_exchange: false,
    singleCheckboxOne: false,
    singleCheckboxTwo: false,
    singleCheckboxThree: false,
    documents: [],
  },
  isLoading: true,
  errorMessage: '',
};

const showAndUpdateCertificate = (state = initialState, { type, payload }) => {
  const redux = new ReduxRoutines(state);
  switch (type) {
    case actions.getShowCertificate.CLEAR:
    case actions.updateStoreCertificate.CLEAR:
      return redux.clear(initialState);
    case actions.getShowCertificate.TRIGGER:
    case actions.updateStoreCertificate.TRIGGER:
      return redux.trigger();
    case actions.getShowCertificate.SUCCESS:
    case actions.updateStoreCertificate.SUCCESS:
      return redux.success(payload);
    case actions.getShowCertificate.FAILURE:
    case actions.updateStoreCertificate.FAILURE:
      return redux.fail(payload);
    default:
      return { ...state };
  }
};

export { showAndUpdateCertificate };
