import { createRoutine, createRoutineCreator, defaultRoutineStages } from 'redux-saga-routines';

export const servicesActions = {
  getServiceInit: createRoutine('GET_SERVICE_INIT'),
  create: createRoutine('CREATE_SERVICE'),
  createdService: createRoutineCreator(['SET', 'RESET'])('CREATED_SERVICE'),
  getServicesList: createRoutine('GET_SERVICES_LIST'),
  servicesRedirect: createRoutineCreator(['SET', 'RESET'])('SERVICES_REDIRECT'),
  getServiceAttributesInit: createRoutine('GET_SERVICE_ATTRIBUTES_INIT'),
  updateAttributes: createRoutine('UPDATE_SERVICE_ATTRIBUTES'),
  getService: createRoutineCreator([...defaultRoutineStages, 'RESET'])('GET_SERVICE'),
  updateService: createRoutine('UPDATE_SERVICE'),
  updateServiceWithValidation: createRoutine('UPDATE_SERVICE_WITH_VALIDATION'),
  getServiceAttributes: createRoutineCreator([...defaultRoutineStages, 'RESET'])('GET_SERVICE_ATTRIBUTES'),
  publishService: createRoutine('PUBLISH_SERVICE'),
  unpublishService: createRoutine('UNPUBLISH_SERVICE'),
  moderateService: createRoutine('MODERATE_SERVICE'),
  changeLangVersionService: createRoutine('CHANGE_LANGUAGE_VERSION_SERVICE'),
  getLangVersionService: createRoutine('GET_LANGUAGE_VERSION_SERVICE'),
};
