import { takeEvery, all, call } from 'redux-saga/effects';
import { geoLocationActions as actions } from '../actions';
import { getGeoLocation } from './workers';

function* getGeoLocationWatcher() {
  yield takeEvery(actions.getGeoLocation.TRIGGER, getGeoLocation);
}

export function* getGeoLocationWatchers() {
  yield all([call(getGeoLocationWatcher)]);
}
