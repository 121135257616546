import { takeEvery, takeLatest, all } from 'redux-saga/effects';
import { showAndUpdateCertificateActions as actions } from '../actions';
import { getShowCertificate, updateStoreCertificate } from './workers';

export function* showAndUpdateCertificateWatchers() {
  yield all([
    yield takeEvery(actions.getShowCertificate.TRIGGER, getShowCertificate),
    yield takeLatest(actions.updateStoreCertificate.TRIGGER, updateStoreCertificate),
  ]);
}
