import API from './API';

class SearchCompanies extends API {
  getAllCompanies = ({ query, langInHeader, currencyInHeader }) =>
    this.get(`/v1/search`, '', query, '', langInHeader, currencyInHeader);

  loadMoreCompanies = ({ page, query }) => this.get(`/v1/search?page=${page}`, '', query);

  getFilterCompaniesCount = ({ query, langInHeader }) => this.get(`/v1/search/count`, '', query, '', langInHeader);

  getSeoSlugSearchParams = ({ path, langInHeader }) =>
    this.get(`/v1/search/seo-slug/${path}/`, '', '', '', langInHeader);

  getSearchInit = ({ langInHeader = '' }) => this.get(`/v1/search/init`, '', {}, '', langInHeader);
}

export default new SearchCompanies();
