import { takeEvery, takeLatest, all, call } from 'redux-saga/effects';
import { servicesActions } from '../actions';
import {
  getServiceInit,
  createService,
  getServicesList,
  getServiceAttributesInit,
  updateAttributes,
  getService,
  updateService,
  updateServiceWithValidation,
  getServiceAttributes,
  publishService,
  unpublishService,
  moderateService,
  changeLangVersionService,
  getLangVersionService,
} from './workers';

function* watchGetServiceInit() {
  yield takeLatest(servicesActions.getServiceInit.TRIGGER, getServiceInit);
}

function* watchCreateService() {
  yield takeLatest(servicesActions.create.TRIGGER, createService);
}

function* watchGetServicesList() {
  yield takeLatest(servicesActions.getServicesList.TRIGGER, getServicesList);
}

function* watchGetServiceAttributesInit() {
  yield takeLatest(servicesActions.getServiceAttributesInit.TRIGGER, getServiceAttributesInit);
}

function* watchUpdateAttributes() {
  yield takeLatest(servicesActions.updateAttributes.TRIGGER, updateAttributes);
}

function* watchGetService() {
  yield takeLatest(servicesActions.getService.TRIGGER, getService);
}

function* watchUpdateService() {
  yield takeLatest(servicesActions.updateService.TRIGGER, updateService);
}
function* watchUpdateServiceWithValidation() {
  yield takeLatest(servicesActions.updateServiceWithValidation.TRIGGER, updateServiceWithValidation);
}

function* watchGetServiceAttributes() {
  yield takeLatest(servicesActions.getServiceAttributes.TRIGGER, getServiceAttributes);
}

function* watchPublishService() {
  yield takeLatest(servicesActions.publishService.TRIGGER, publishService);
}

function* watchUnpublishService() {
  yield takeLatest(servicesActions.unpublishService.TRIGGER, unpublishService);
}

function* watchModerateService() {
  yield takeLatest(servicesActions.moderateService.TRIGGER, moderateService);
}
function* watchChangeLangVersionService() {
  yield takeEvery(servicesActions.changeLangVersionService.TRIGGER, changeLangVersionService);
}
function* watchGetLangVersionService() {
  yield takeLatest(servicesActions.getLangVersionService.TRIGGER, getLangVersionService);
}

export function* watchServices() {
  yield all([
    call(watchGetServiceInit),
    call(watchCreateService),
    call(watchGetServicesList),
    call(watchGetServiceAttributesInit),
    call(watchUpdateAttributes),
    call(watchGetService),
    call(watchUpdateService),
    call(watchUpdateServiceWithValidation),
    call(watchGetServiceAttributes),
    call(watchPublishService),
    call(watchUnpublishService),
    call(watchModerateService),
    call(watchChangeLangVersionService),
    call(watchGetLangVersionService),
  ]);
}
