import { ReduxRoutines } from 'utils/redux-helper';
import { geoLocationActions as actions } from './actions';

const initialState = {
  item: null,
  errorMessage: '',
  isLoading: false,
};

export const geoLocation = (state = initialState, { type, payload }) => {
  const redux = new ReduxRoutines(state);
  switch (type) {
    case actions.getGeoLocation.REQUEST:
      return redux.trigger();
    case actions.getGeoLocation.SUCCESS:
      return redux.success(payload);
    case actions.getGeoLocation.FAILURE:
      return redux.fail(payload);
    default:
      return { ...state };
  }
};
