import { ReduxRoutines } from 'utils/redux-helper';
import { servicesActions as actions } from './actions';
import { initialServiceValues, initialServiceAttributesValues } from './data';

const initialState = {
  serviceInit: {
    company_name: '',
    time_units: [],
    currencies: [],
    feedback_form_types: [],
  },
  servicesList: [],
  shouldServicesRedirect: false,
  createdServiceId: null,
  serviceAttributesInit: {
    time_units: [],
    currencies: [],
    categories: [],
    countries: [],
    country_groups: [],
    immigration_purposes: [],
  },
  initialServiceValues,
  initialServiceAttributesValues,
  errorMessage: '',
  isLoading: false,
  langInHeader: '',
  filled_locales: [],
};

export const services = (state = initialState, { type, payload }) => {
  const redux = new ReduxRoutines(state);

  switch (type) {
    case actions.create.REQUEST:
      return redux.trigger({
        createdServiceId: initialState.createdServiceId,
      });

    case actions.getServicesList.REQUEST:
    case actions.getServiceAttributesInit.REQUEST:
    case actions.getServiceInit.REQUEST:
    case actions.updateService.REQUEST:
    case actions.updateServiceWithValidation.REQUEST:
    case actions.publishService.REQUEST:
    case actions.unpublishService.REQUEST:
    case actions.getLangVersionService.REQUEST:
    case actions.getServiceAttributes.REQUEST:
    case actions.getService.REQUEST:
    case actions.updateAttributes.REQUEST:
      return redux.trigger();
    case actions.create.SUCCESS:
    case actions.getServicesList.SUCCESS:
    case actions.getServiceAttributesInit.SUCCESS:
    case actions.getServiceInit.SUCCESS:
    case actions.updateService.SUCCESS:
    case actions.updateServiceWithValidation.SUCCESS:
    case actions.publishService.SUCCESS:
    case actions.unpublishService.SUCCESS:
    case actions.changeLangVersionService.SUCCESS:
    case actions.getLangVersionService.SUCCESS:
    case actions.getServiceAttributes.SUCCESS:
    case actions.getService.SUCCESS:
    case actions.updateAttributes.SUCCESS:
      return redux.success(payload);
    case actions.create.FAILURE:
    case actions.getServicesList.FAILURE:
    case actions.getServiceAttributesInit.FAILURE:
    case actions.getServiceInit.FAILURE:
    case actions.updateService.FAILURE:
    case actions.updateServiceWithValidation.FAILURE:
    case actions.publishService.FAILURE:
    case actions.unpublishService.FAILURE:
    case actions.changeLangVersionService.FAILURE:
    case actions.getLangVersionService.FAILURE:
    case actions.getServiceAttributes.FAILURE:
    case actions.getService.FAILURE:
    case actions.updateAttributes.FAILURE:
      return redux.fail(payload);

    case actions.createdService.SET:
      return { ...state, ...payload };
    case actions.createdService.RESET:
      return { ...state, createdServiceId: initialState.createdServiceId };

    case actions.servicesRedirect.SET:
      return { ...state, shouldServicesRedirect: true };
    case actions.servicesRedirect.RESET:
      return { ...state, shouldServicesRedirect: false };

    case actions.getService.RESET:
      return { ...state, initialServiceValues: { ...initialServiceValues } };

    case actions.getServiceAttributes.RESET:
      return { ...state, initialServiceAttributesValues: { ...initialServiceAttributesValues } };

    default:
      return { ...state };
  }
};
