import { createRoutine } from 'redux-saga-routines';

const init = createRoutine('INIT');
const registration = createRoutine('REGISTRATION');
const registrationWeb = createRoutine('REGISTRATION_WEB');
const login = createRoutine('LOGIN');
const loginWeb = createRoutine('LOGIN_WEB');
const socialLogin = createRoutine('SOCIAL_LOGIN');
const socialLoginWeb = createRoutine('SOCIAL_LOGIN_WEB');
const socialSignUp = createRoutine('SOCIAL_SIGNUP');
const socialSignUpWeb = createRoutine('SOCIAL_SIGNUP_WEB');
const logout = createRoutine('LOGOUT');
const emailActivate = createRoutine('EMAIL_ACTIVATE');
const sendActivationEmail = createRoutine('SEND_ACTIVATION_EMAIL');

export default {
  init,
  registration,
  registrationWeb,
  login,
  loginWeb,
  logout,
  socialLogin,
  socialLoginWeb,
  socialSignUp,
  socialSignUpWeb,
  emailActivate,
  sendActivationEmail,
};
