import { takeLatest, call, put, all } from 'redux-saga/effects';
import SearchCompanies from '@services/searchCompanies';
import actions from './actions';

export function* fetchSearchInitFromServer(e) {
  try {
    const payload = e.payload || { langInHeader: '' };
    const { data } = yield call(SearchCompanies.getSearchInit, payload);
    yield put(
      actions.fetchSearchInit.success({
        data,
      }),
    );
  } catch (err) {
    yield put(actions.fetchSearchInit.failure(err));
  }
}

export function* getSearchInitSaga() {
  yield all([yield takeLatest(actions.fetchSearchInit.TRIGGER, fetchSearchInitFromServer)]);
}
