import { takeLatest, call, put, all } from 'redux-saga/effects';
import WebSubscription from '@services/webSubscription';
import actions from './actions';

export function* postSubscribeFromServer(action) {
  const { callBack } = action.payload;
  try {
    const { data } = yield call(WebSubscription.subscribe, action.payload);

    if (typeof callBack === 'function') {
      callBack();
    }
    yield put(actions.postSubscribe.success({ data }));
  } catch (err) {
    yield put(actions.postSubscribe.failure(err));
  }
}

export function* postSubscribeArticlesFromServer(action) {
  const { ...payload } = action.payload;
  try {
    const { data } = yield call(WebSubscription.subscribeArticles, payload);
    yield put(actions.postSubscribeArticles.success({ dataArticles: { ...data.data } }));
  } catch (err) {
    yield put(actions.postSubscribeArticles.failure(err));
  }
}

export function* postSubscribeSaga() {
  yield all([
    yield takeLatest(actions.postSubscribe.TRIGGER, postSubscribeFromServer),
    yield takeLatest(actions.postSubscribeArticles.TRIGGER, postSubscribeArticlesFromServer),
  ]);
}
