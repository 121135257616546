import { call, put } from 'redux-saga/effects';
import servicesAPI from '@services/services';
import { servicesActions } from '../../actions';

export function* getServiceInit(action) {
  try {
    yield put(servicesActions.getServiceInit.request());

    const { data } = yield call(servicesAPI.getServiceInit, action.payload);

    yield put(
      servicesActions.getServiceInit.success({
        serviceInit: {
          company_name: data.data?.company_name,
          time_units: data.data?.time_units,
          currencies: data.data?.currencies,
          feedback_form_types: data.data?.feedback_form_types,
        },
      }),
    );
  } catch (err) {
    yield put(servicesActions.getServiceInit.failure(err));
  }
}
