import { parseCookies, setCookie as setCookieNookie, destroyCookie } from 'nookies';
import { IS_BROWSER } from 'constants/global';

export const setCookie = (
  key,
  value,
  options = {
    maxAge: 100000000,
    path: '/',
  },
  ctx = null,
) => {
  setCookieNookie(ctx, key, value, options);
};

export const removeCookie = (key, ctx = null, options = { path: '/' }) => {
  destroyCookie(ctx, key, options);
};

export const getCookieFromBrowser = (key) => {
  const cookies = parseCookies();
  return cookies[key];
};

export const getCookieFromServer = (key, ctx) => {
  const cookies = parseCookies(ctx);
  return cookies[key];
};

export const getCookie = (key, ctx) => {
  return IS_BROWSER ? getCookieFromBrowser(key) : getCookieFromServer(key, ctx);
};
