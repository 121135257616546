import { createSelector } from 'reselect';

const selectSearchInit = (state) => state.searchInit.data;
const selectError = (state) => state.searchInit.errorMessage;
const selectIsLoading = (state) => state.searchInit.isLoading;

const loadingAndErrors = () =>
  createSelector(selectIsLoading, selectError, (isLoading, errorMessage) => ({
    isLoading,
    errorMessage,
  }));

export { selectSearchInit, selectIsLoading, selectError, loadingAndErrors };
