class ErrorParser {
  constructor(parser) {
    this.parser = parser;
  }

  parseErrorMessaage = (err) => {
    const { code, errors: errorObject, error_code, message, subscription_packages } = err?.response?.data;
    let errorMessage = message;
    if (Object.keys(errorObject || {}).length) {
      errorMessage = Object.values(errorObject);
    }
    return { errorMessage, errorCode: Number(code), errorObject, error_code, subscription_packages };
  };
}

export default ErrorParser;
