import { call, put } from 'redux-saga/effects';
import servicesAPI from '@services/services';
import { servicesActions } from '../../actions';

export function* moderateService(action) {
  try {
    yield put(servicesActions.moderateService.request());
    yield call(servicesAPI.moderate, action.payload);
    yield put(servicesActions.moderateService.success());
  } catch (err) {
    console.log(err);
    yield put(servicesActions.moderateService.failure(err));
  }
}
