import { ReduxRoutines } from '@utils/redux-helper';
import actions from './actions';

const initialState = {
  messengers: [],
  office_types: [],
  payment_methods: [],
  feedback_form_types: [],
  cash_currencies: [],
  cashless_currencies: [],
  cryptocurrencies: [],
  company_types: [],
  errorMessage: '',
  isLoading: false,
};

function getInitData(state = initialState, { type, payload }) {
  const redux = new ReduxRoutines(state);
  switch (type) {
    case actions.getInitData.TRIGGER:
      return redux.trigger();
    case actions.getInitData.SUCCESS:
      return redux.success(payload);
    case actions.getInitData.FAILURE:
      return redux.fail(payload);
    default:
      return { ...state };
  }
}

export default getInitData;
