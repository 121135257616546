import API from './API';

class CompanyDataService extends API {
  getCompanyMainInfo = (uuid, lang) => this.get(`/v1/company/${uuid}`, '', {}, '', lang, '');

  getCompanyListServices = ({ uuid, query }) => this.get(`/v1/company/${uuid}/services`, '', query);

  getCompanyServiceCategories = () => this.get('/v1/categories/parents');

  getCompanyService = (uuid) => this.get(`/v1/service/${uuid}`);

  serviceDataPreview = ({ uuid, token }) => this.get(`/v1/account/service/preview/${uuid}`, token);

  getAllCompanies = (locale) => this.get(`/v1/companies`, '', {}, '', locale, '');
}

export default new CompanyDataService();
