import { takeLatest, call, put, all } from 'redux-saga/effects';
import AuthService from '@services/authService';
import actions from './actions';

export function* emailActivate(e) {
  try {
    const { data } = yield call(AuthService.activateEmail, e.payload);
    yield put(actions.emailActivate.success({ user: data.data }));
  } catch (err) {
    yield put(actions.emailActivate.failure(err));
  }
}

export function* sendActivationEmail(e) {
  try {
    const { data } = yield call(AuthService.sendActivationEmail, e.payload);
    yield put(actions.sendActivationEmail.success({ user: data.data }));
  } catch (err) {
    yield put(actions.sendActivationEmail.failure(err));
  }
}

export function* authSaga() {
  yield all([
    yield takeLatest(actions.emailActivate.TRIGGER, emailActivate),
    yield takeLatest(actions.sendActivationEmail.TRIGGER, sendActivationEmail),
  ]);
}
