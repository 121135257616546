import ErrorParser from './errorParser';

export default class {
  constructor(state) {
    this.state = state;
    this.errorParser = new ErrorParser();
  }

  init = () => {
    return {
      ...this.state,
      errorMessage: '',
      errorCode: '',
      errorObject: {},
    };
  };

  trigger = (initState) => ({
    ...this.state,
    ...initState,
    isLoading: true,
    errorObject: {},
  });

  success = (newValues) => ({
    ...this.state,
    isLoading: false,
    errorMessage: '',
    errorObject: {},
    ...newValues,
  });

  fail = (payload, initState) => {
    this.parsed = this.errorParser.parseErrorMessaage(payload);
    const { errorMessage, errorCode, errorObject, error_code, subscription_packages } = this.parsed;
    return {
      ...this.state,
      ...initState,
      isLoading: false,
      errorCode,
      errorObject,
      errorMessage,
      subscription_packages,
      error_code,
    };
  };

  clear = (initState) => ({
    isLoading: false,
    ...initState,
    errorMessage: '',
    errorCode: '',
  });
}
