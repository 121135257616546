import { ReduxRoutines } from '@utils/redux-helper';
import actions from './actions';

const initialState = {
  data: {},
  dataArticles: {},
  isLoading: false,
  errorMessage: '',
  errorObject: {},
  errorCode: NaN,
};
const postSubscribe = (state = initialState, { type, payload }) => {
  const redux = new ReduxRoutines(state);
  switch (type) {
    case actions.postSubscribe.CLEAR:
    case actions.postSubscribeArticles.CLEAR:
      return redux.clear({ ...initialState, ...payload });
    case actions.postSubscribe.TRIGGER:
    case actions.postSubscribeArticles.TRIGGER:
      return redux.trigger();
    case actions.postSubscribe.SUCCESS:
    case actions.postSubscribeArticles.SUCCESS:
      return redux.success(payload);
    case actions.postSubscribe.FAILURE:
    case actions.postSubscribeArticles.FAILURE:
      return redux.fail(payload);
    default:
      return { ...state };
  }
};

export { postSubscribe };
