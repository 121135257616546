import sortBy from 'lodash/sortBy';

const FISTS_COUNTRY_LIST = ['ru', 'by', 'ua'];

const selectAllSourceCountries = (state) =>
  sortBy(
    state?.countries?.reduce((acc, item, ind) => {
      const indexCurr = FISTS_COUNTRY_LIST.findIndex((val) => val === item.code);
      if (indexCurr >= 0) {
        acc.unshift({ ...item, sortId: indexCurr });
      } else {
        acc.push({ ...item, sortId: FISTS_COUNTRY_LIST.length + ind });
      }
      return acc;
    }, []),
    'sortId',
  ) || [];

export { selectAllSourceCountries };
