import { takeLatest, call, put, all } from 'redux-saga/effects';
import Subscription from '@services/Subscription';
import actions from './actions';

export function* getAllSubscriptionsFromServer(action) {
  try {
    const { data } = yield call(Subscription.getAllSubscription, action.payload);
    // if request successfully finished
    const allSubscriptions = data.data;

    if (allSubscriptions.length > 0) {
      yield put(actions.getAllSubscriptions.success({ data: allSubscriptions }));
    } else {
      yield put(actions.getAllSubscriptionsCustom.redirect());
    }
  } catch (err) {
    // if request failed
    yield put(actions.getAllSubscriptions.failure(err));
  }
}

export function* getAllSubscriptionsSaga() {
  yield all([yield takeLatest(actions.getAllSubscriptions.TRIGGER, getAllSubscriptionsFromServer)]);
}
