import { ReduxRoutines } from '@utils/redux-helper';
import actions from './actions';

const initialState = {
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  position: '',
  email_verified: true,
  email_notifications: false,
  errorMessage: '',
  isLoading: true,
  role: null,
  uuid: '',
  registered_at: '',
  company: {},
};

function Profile(state = initialState, { type, payload }) {
  const redux = new ReduxRoutines(state);
  switch (type) {
    case actions.profile.TRIGGER:
    case actions.profileUpdate.TRIGGER:
    case actions.uploadAvatar.TRIGGER:
      return redux.trigger();
    case actions.profile.SUCCESS:
    case actions.profileUpdate.SUCCESS:
    case actions.uploadAvatar.SUCCESS:
      return redux.success(payload);
    case actions.clearStoreProfile.SUCCESS:
      return redux.success(initialState);
    case actions.profile.FAILURE:
    case actions.profileUpdate.FAILURE:
    case actions.uploadAvatar.FAILURE:
      return redux.fail(payload);
    default:
      return { ...state };
  }
}

export default Profile;
