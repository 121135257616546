import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
export const IW_URL = publicRuntimeConfig.IW_URL;
export const IW_API_URL = publicRuntimeConfig.IW_API_URL;
export const IW_API_URL_INTERN = publicRuntimeConfig.IW_API_URL_INTERN;
export const RUS_API_URL = publicRuntimeConfig.RUS_API_URL;
export const AUTH_ENDPOINT = publicRuntimeConfig.AUTH_ENDPOINT;
export const IW_CDN_IMAGES_URL = publicRuntimeConfig.IW_CDN_IMAGES_URL;
export const API_CDN_URL = publicRuntimeConfig.API_CDN_URL;
export const CDN_URL_VIDEO = publicRuntimeConfig.CDN_URL_VIDEO;
export const IW_CDN_URL = publicRuntimeConfig.IW_CDN_URL;
export const ADMIN_URL = publicRuntimeConfig.ADMIN_URL;
export const GENERAL_DOMAIN = publicRuntimeConfig.GENERAL_DOMAIN;
export const GENERAL_URL = publicRuntimeConfig.GENERAL_URL;
export const API_BLOG = 'https://iworld.com/wp-json/api';

export const IS_DEV = publicRuntimeConfig.IS_DEV;
export const IS_PROD = publicRuntimeConfig.IS_PROD;
export const COUNTRIES_PASS_IMAGES = `${API_CDN_URL}/countries/passport/`;

