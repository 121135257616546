import { ReduxRoutines } from '@utils/redux-helper';
import { HYDRATE } from 'next-redux-wrapper';
import actions from './actions';

const initialState = {
  data: [],
  meta: {},
  isLoading: false,
  errorMessage: '',
};

const getUserLeads = (state = initialState, { type, payload }) => {
  const redux = new ReduxRoutines(state);
  switch (type) {
    case HYDRATE: {
      const { getUserLeads } = payload;
      return { ...state, ...getUserLeads };
    }
    case actions.getUserLeads.TRIGGER:
      return redux.trigger();
    case actions.getUserLeads.SUCCESS:
      return redux.success(payload);
    case actions.getUserLeads.FAILURE:
      return redux.fail(payload);
    default:
      return state;
  }
};

export { getUserLeads };
